const validateAgainstAnotherField = (fieldsToValidateAgainst, newErrors) => {
	const errorMessages = fieldsToValidateAgainst?.reduce((acc, _item) => {
		const currentKey = _item;
		const keysFromNewErrors = Object.keys(newErrors.errors);

		if (keysFromNewErrors.includes(currentKey)) {
			const currentErrorMessage = newErrors.errors[currentKey].message;
			acc.push(currentErrorMessage);
		}
		return acc;
	}, []);
	return errorMessages;
};

export default validateAgainstAnotherField;
