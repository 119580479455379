const buddy = {
	webFonts: [
		'https://fonts.buddyinsurance.com/',
	],
	styles: {
		body: {
			fontSize: '16px',
			fontFamily: 'RationalText-Book, sans-serif',
			fontWeight: 400,
			color: 'var(--color-text-primary)',
			lineHeight: 1.35,
			'@media (min-width: 768px)': {
				fontSize: '18px',
			},
		},
		h1: {
			color: 'var(--color-text-primary)',
			fontFamily: 'RationalDisplay-Bold, sans-serif',
			fontWeight: 'bold',
			fontSize: '2rem',
			lineHeight: 1,
			marginBottom: '0.5em',
			'@media (min-width: 992px)': {
				fontSize: '3rem',
			},
		},
		h2: {
			color: 'var(--color-text-primary)',
			fontFamily: 'RationalDisplay-Bold, sans-serif',
			fontWeight: 'bold',
			fontSize: '1.8rem',
			marginBottom: '0.25em',
			lineHeight: 1,
			'@media (min-width: 768px)': {
				fontSize: 36,
			},
			'@media (min-width: 992px)': {
				fontSize: '2.5rem',
			},
		},
		h3: {
			color: 'var(--color-text-primary)',
			fontFamily: 'RationalText-Book, sans-serif',
			fontSize: '1.5rem',
			lineHeight: 1.25,
			fontWeight: 400,
		},
		h4: {
			color: 'var(--color-text-primary)',
			fontFamily: 'RationalText-Medium, sans-serif',
			fontSize: '0.625rem',
			letterSpacing: '10%',
			textTransform: 'uppercase',
			lineHeight: 1.25,
			fontWeight: 400,
		},
		a: {
			fontSize: '14px',
			textDecoration: 'underline',
			lineHeight: 1.5,
		},
		'.card': {
			background: 'var(--background-primary)',
			padding: '2em',
			borderRadius: '10px',
			'@media (min-width: 992px)': {
				padding: '3em',
			},
		},
		'.button-primary': {
			fontFamily: 'RationalText-Book, sans-serif',
			fontSize: '.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			lineHeight: 1.5,
			color: 'var(--color-text-primary)',
			backgroundColor: '#fff',
			border: '1.5px solid var(--color-text-primary)',
			paddingTop: '1.25rem',
			paddingBottom: '1.25rem',
			boxShadow: '3px 3px rgba(70, 70, 14, .2)',
			whiteSpace: 'nowrap',
			width: '100%',
			height: '3.5rem',
			padding: 'auto',
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: '#FBF9EF',
			},
			'@media (min-width: 768px)': {
				width: 366,
				height: 56,
			},
		},
		'.button-secondary': {
			fontSize: '14px',
			textDecoration: 'underline',
			lineHeight: 1.5,
		},
		'.input-text': {
			backgroundColor: 'transparent',
			borderStyle: 'none',
			flexGrow: 1,
			fontSize: '16px', // if you go smaller, this will cause a zoom-in on iPhones by default.
			lineHeight: 1.5,
			color: '#0a242d',
			display: 'block',
			marginTop: '0.75rem',
			width: '100%',
			padding: '0.75rem 0',
			paddingBottom: '0.75rem',
			borderBottom: '1px solid #0a242d',
			'@media (min-width: 768px)': {
				fontSize: '18px',
			},
		},
		'.input-select': {
			background: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgOCA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAxLjVMNCA1LjVMNyAxLjUiIHN0cm9rZT0iIzBBMjQyRCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 50%',
			appearance: 'none',
			// these two props ^ override safari's default styles and sets the dropdown as the background on the select element
			borderRadius: '0%',
			backgroundColor: 'transparent',
			borderStyle: 'none',
			flexGrow: 1,
			fontSize: '16px', // if you go smaller, this will cause a zoom-in on iPhones by default.
			lineHeight: 1.5,
			color: '#0a242d',
			display: 'block',
			marginTop: '0.75rem',
			width: '100%',
			padding: '0.75rem 0',
			paddingBottom: '0.75rem',
			borderBottom: '1px solid #0a242d',
			'@media (min-width: 768px)': {
				fontSize: '18px',
			},
		},
		'.input-label': {
			fontFamily: 'RationalText-Book, sans-serif',
			fontSize: '12px',
			lineHeight: 1.25,
			color: 'rgba(10, 36, 45, 0.6)',
		},
		'.input-invalid': {
			color: '#D62719',
			borderColor: '#D62719',
		},
		'.input-error': {
			lineHeight: 1.5,
			fontSize: 14,
			paddingTop: '6px',
			color: '#D62719',
			fontStyle: 'italic',
			'::before': {
				content: "'! '",
			},
		},
		'.bg-primary': {
			background: 'var(--background-primary)',
		},
		'.bg-secondary': {
			background: 'var(--background-secondary)',
		},
		'.progress-bar-label': {
			fontFamily: 'RationalText-Book, sans-serif',
			fontSize: '12px',
			lineHeight: 1.25,
			color: 'rgba(10, 36, 45, 0.6)',
			marginTop: '2rem',
			width: '100%',
		},
		'.progress-bar-container': {
			marginTop: '',
			width: '100%',
			borderRadius: '0.5rem',
			height: '0.75rem',
			border: 'solid',
			borderWidth: '0.15px',
			borderColor: 'rgba(10, 36, 45, 0.3)',
			borderOpacity: '0.7',
		},
		'.progress-bar': {
			backgroundColor: 'var(--color-primary)',
			zIndex: 20,
			height: '100%',
			borderRadius: '0.5rem',
		},
	},
};

export default buddy;
