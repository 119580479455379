import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary><App /></ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.REACT_APP_ENV === 'development') {
	// eslint-disable-next-line no-console
	reportWebVitals(console.log);
}
