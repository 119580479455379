import cloneDeep from 'lodash/cloneDeep';
import { flattenApplicationFields } from '@buddy-technology/ion-helpers';
import convertFieldIDtoRHF from './convertFieldIDtoRHF';
import { CONTENT_POSITION_TYPES } from '../models/dictionary';

/*
	setUpViews is the exported function in this file.
	each helper function prefixed with '_' are internal helper functions used inside setupViews.
*/

// Array.findLastIndex isn't available until node 18
function findLastIndex(array, callback) {
	for (let index = array.length; index >= 0; index -= 1) {
		const element = array[index];
		if (callback(element, index, array)) {
			return index;
		}
	}
	return -1;
}

function removeDuplicateObjectsById(array) {
	return array.filter(
		// only return if found id matches the index of original found id.
		(el, index, self) => self.findIndex(({ id: selfId }) => selfId === el.id) === index,
	);
}

function _getFieldsByViewId(fieldsArray) {
	const fieldsByViewId = fieldsArray.reduce((running, current) => {
		const { view: viewId } = current.uiDisplay || {};
		if (viewId) {
			if (Array.isArray(running[viewId])) {
				running[viewId].push(current);
				return running;
			}
			return { ...running, [viewId]: [current] };
		}
		return running;
	}, {});
	return fieldsByViewId;
}

function _mapFieldObjectsToContent(views, ionFieldsArray) {
	const newViews = views.map((viewObj) => {
		const { content = [] } = viewObj;
		if (Array.isArray(content)) {
			const updatedContent = content.map((contentPiece) => {
				if (contentPiece.tabField) {
					const tabFieldObject = ionFieldsArray.find(({ id }) => id === convertFieldIDtoRHF(contentPiece.tabField));

					if (!tabFieldObject) {
						throw new Error(`Cannot find the field tabField references. ${contentPiece.tabField}`);
					}
					return { ...contentPiece, tabField: tabFieldObject };
				}
				if (contentPiece.fields) {
					const fieldObjects = contentPiece.fields.map(
						(fieldPath) => ionFieldsArray.find(({ id }) => id === convertFieldIDtoRHF(fieldPath)),
					);
					const cleanedFieldObjects = fieldObjects.filter((obj) => obj && typeof obj === 'object');
					return { ...contentPiece, fields: cleanedFieldObjects };
				}
				return contentPiece;
			});
			return { ...viewObj, content: updatedContent };
		}
		return viewObj;
	});
	return newViews;
}

function _mapContentByTypeOrPosition(content = []) {
	const {
		BEFORE_FIELDS,
		AFTER_FIELDS,
		BELOW_NAV,
		NONE,
		fieldsContent,
	} = content.reduce((acc, current) => {
		// if for some reason folks have mixed up the implementations and this function is invoked for content without position props, we'll deal with it here
		const { position = 'NONE', fields } = current;
		if (fields) {
			const existingFields = acc.fieldsContent || [];
			return { ...acc, fieldsContent: [...existingFields, ...fields] };
		}

		const arrayOfPositionType = acc[position] || [];
		// inject the index by position type in our new object. This is to support current implementations that rely on these specific css selectors (eg: viewId-content-container-BEFORE-0)
		const newContentObj = { ...current, positionIndex: arrayOfPositionType.length };
		return { ...acc, [position]: [...arrayOfPositionType, newContentObj] };
	}, {}) || {};

	return {
		contentBeforeFields: BEFORE_FIELDS,
		contentAfterFields: AFTER_FIELDS,
		contentBelowNav: BELOW_NAV,
		contentNoPosition: NONE,
		fieldsContent,
	};
}

function _handleLegacyPositioning(views) {
	const newViews = views.map((view) => {
		const { content = [] } = view;
		const OLD_POSITIONS = [CONTENT_POSITION_TYPES.AFTER_FIELDS, CONTENT_POSITION_TYPES.BEFORE_FIELDS];
		const hasOldPositionProp = content.some(({ position }) => OLD_POSITIONS.includes(position));
		// only do this if old positions are present.
		if (hasOldPositionProp) {
			const {
				contentBeforeFields = [],
				contentNoPosition = [],
				contentAfterFields = [],
				contentBelowNav = [],
				fieldsContent = [],
			} = _mapContentByTypeOrPosition(content);
			const newContent = [
				...contentBeforeFields,
				...contentNoPosition,
				{ fields: fieldsContent },
				...contentAfterFields,
				...contentBelowNav,
			];
			return { ...view, content: newContent };
		}
		return view;
	});
	return newViews;
}

function _injectFieldsIntoViews(views, viewsThatNeedFields, fieldsByViewId) {
	const newViews = views.map((view) => {
		const { content = [], id } = view;
		const doesNeedFields = viewsThatNeedFields.includes(id);
		if (doesNeedFields) {
			const fieldsToInject = fieldsByViewId[view.id].sort((a, b) => {
				const argA = a.uiDisplay?.order || 0;
				const argB = b.uiDisplay?.order || 0;
				return (argA - argB);
			});

			// do our best to inject fields in the best place possible. If fields already exist put them there. Otherwise try to place them between any BEFORE_FIELDS and AFTER FIELDS if they exist. If no other content has position, add them to the beginning.
			const existingFieldsIndex = findLastIndex(content, (el) => !!el?.fields);
			if (existingFieldsIndex !== -1) {
				const mergedFields = [...content[existingFieldsIndex].fields, ...fieldsToInject];
				// if for some reason we already have fields in content AND fields pointing to a view, merge them.
				const fieldsToAdd = { fields: removeDuplicateObjectsById(mergedFields) };
				content.splice(existingFieldsIndex, 1, fieldsToAdd);
				return view;
			}

			const beforeFieldsIndex = findLastIndex(content, (el) => el?.position === CONTENT_POSITION_TYPES.BEFORE_FIELDS);

			if (beforeFieldsIndex !== -1) {
				content.splice(beforeFieldsIndex + 1, 0, { fields: fieldsToInject });
				return view;
			}

			const afterFieldsIndex = findLastIndex(content, (el) => el?.position === CONTENT_POSITION_TYPES.AFTER_FIELDS);

			if (afterFieldsIndex !== -1) {
				content.splice(afterFieldsIndex - 1, 0, { fields: fieldsToInject });
				return view;
			}

			return { ...view, content: [{ fields: fieldsToInject }, ...content] };
		}
		return view;
	});
	return newViews;
}
/**
 * @function setupViews
 * @description this function will create the view objects necessary for rendering views with proper fields and content.
 * @param  {Object} rawIon - the incoming ion.
 * @returns {Object} - a modified ION object with its views restructured to include all necessary fields/content info.
 */
function setUpViews(rawIon) {
	const clone = cloneDeep(rawIon);
	const flattenedFields = flattenApplicationFields(clone?.application.fields);
	const { views } = clone.application;

	const updatedViews = _mapFieldObjectsToContent(views, flattenedFields);

	const backwardsCompatibleViews = _handleLegacyPositioning(updatedViews);
	Object.assign(clone.application, { views: backwardsCompatibleViews });

	// for legacy support where the view id is listed in the field's uiDisplay property
	const fieldsByViewId = _getFieldsByViewId(flattenedFields);
	// these are the views that we'll need to inject fields into
	const viewsNeedingFields = Object.keys(fieldsByViewId);
	if (viewsNeedingFields.length) {
		const newViews = _injectFieldsIntoViews(backwardsCompatibleViews, viewsNeedingFields, fieldsByViewId);

		Object.assign(clone.application, { views: newViews });
	}
	return clone;
}

export default setUpViews;
