import React from 'react';
import Bowser from 'bowser';
import { useEventContext } from '../../context';
import {
	base64toBlob,
} from '../../utils';

const AnchorTag = ({ node, href, ...props }) => {
	const { eventCallback } = useEventContext();
	// don't render emails as links
	if (href.includes('mailto:')) {
		return <span>{props.children}</span>;
	}
	/* This code is necessary as Chrome (and Chromium-based browsers) don't allow navigating to data uris.
				This has been tested on the following browsers:
					- Safari (mac, iOS)
					- Chrome (mac, Windows, iOS)
					- Firefox (mac, Windows, iOS*)
					*on Firefox iOS, the link opens if you specifically select open in a new tab. It opens directly if you leave remove target="_blank" from the link, so we added that condition for firefox IOS only.
				*/
	if (href.includes('data:application/pdf;base64,')) {
		const browser = Bowser.parse(window.navigator.userAgent);
		const isFirefoxIos = browser.os.name === 'iOS' && browser.browser.name === 'Firefox';
		const trimCount = href.indexOf(',') + 1;
		const base64 = href.substring(trimCount);
		const file = base64toBlob(base64, 'application/pdf');
		const fileUrl = URL.createObjectURL(file);
		if (isFirefoxIos) {
			return (
			// eslint-disable-next-line jsx-a11y/anchor-has-content
				<a
					{...props}
					href={fileUrl} // overwrite href with our fileUrl
					onClick={() => eventCallback('onExternalLink', { timestamp: Date.now(), externalLinkUrl: fileUrl })}
				/>
			);
		}
		return (
			// eslint-disable-next-line jsx-a11y/anchor-has-content
			<a
				{...props}
				href={fileUrl} // overwrite href with our fileUrl
				onClick={() => eventCallback('onExternalLink', { timestamp: Date.now(), externalLinkUrl: fileUrl })}
				target="_blank"
				rel="noopener noreferrer"
			/>
		);
	}
	return (
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		<a
			{...props}
			href={href}
			onClick={() => eventCallback('onExternalLink', { timestamp: Date.now(), externalLinkUrl: href })}
			target="_blank"
			rel="noopener noreferrer"
		/>
	);
};

export default {
	a: AnchorTag,
};
