import { ION_TO_JOI } from '@buddy-technology/ion-helpers';
import logInfo from '../utils/logInfo';
import { useDataContext } from '../context';

import isOnlyTopLevelFields from '../utils/isOnlyTopLevelFields';

/**
  * @typedef {Function} ValidateFieldsFunction
  * Validates the specified action fields.
  *
  * @param {Array} actionFields - The fields to validate.
  * @param {Object} [options={}] - Options for validation.
  * @param {Array} [options.fieldsToTrigger] - The fields to trigger validation on (default is all fields rendered in view).
  * @param {Boolean} [options.shouldFocus=true] - Whether to focus the top field with an error or not.
  * @returns {Promise<Boolean>} - Returns true if validation passes, otherwise false.
*/

/**
 * Custom hook to validate fields using a given schema and context.
 * @param {Object} [options={}] - The options object.
 * @param {Function} options.trigger - Function to trigger validation on the fields.
 * @param {Function} options.getValues - Function to get the current values of the fields.
 * @param {Array} [options.flatApplication=[]] - The flat application data.
 * @param {Array} [options.viewContent=[]] - The content array containing field definitions.
 * @returns {ValidateFieldsFunction} - A function to validate the specified action fields.
 */
function useValidateFields({
	trigger,
	getValues,
	flatApplication = [],
	viewContent = [],
} = {}) {
	const { debug, variables } = useDataContext();

	return async function validateFields(actionFields, { fieldsToTrigger, shouldFocus = true } = {}) {
		const isTopLevelFields = isOnlyTopLevelFields(actionFields);

		// If we only have top-level objects, we can skip validation.
		if (isTopLevelFields) {
			return true;
		}

		const currentFieldIds = viewContent.reduce((running, current) => {
			if (current.fields) {
				const fieldIds = current.fields.map(({ id }) => id);
				return [...running, ...fieldIds];
			}
			return running;
		}, []);

		const values = getValues(actionFields);

		const actionFieldState = actionFields.reduce((acc, fieldId, index) => {
			acc[fieldId] = values[index];
			return acc;
		}, {});

		const actionFieldObjects = flatApplication.filter(({ id }) => actionFields.includes(id));
		const actionFieldSchema = ION_TO_JOI(actionFieldObjects);

		const { error } = actionFieldSchema.validate(actionFieldState, {
			allowUnknown: true,
			convert: true,
			render: true,
			errors: { wrap: { label: '[]' } },
			context: {
				variables,
				TODAY: new Date().setHours(-24, 0, 0, 0),
			},
		});

		if (error && debug) {
			logInfo('Action Fields Validation error:', error);
		}

		const triggerResult = await trigger(fieldsToTrigger || currentFieldIds, { shouldFocus });

		return !error && triggerResult;
	};
}

export default useValidateFields;
