import React, { forwardRef, useEffect } from 'react';
import { useEventContext } from '../../context/EventContext';

/**
 * @typedef  {Object} TextareaProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input - the input label
 * @property {Function} register - RHF method for initializing a field
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled
 * @property {number} [rows] - An HTML property for manually setting the input height
 * @property {string} [placeholder] - A default text to show before entering or selecting an item
 * @property {string} [autocomplete] - An HTML attribute for setting a value autocompletion
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * @description A large text box for users to write in. An extension of the HTML Textarea element.
 * @param {TextareaProps} props
 * @param {Object} [ref]
 */
const Textarea = ({
	id,
	htmlId,
	label = null,
	register,
	errors,
	isEndorsable,
	isRequired,
	rows,
	placeholder,
	autocomplete,
	isDisabled,
	helpText,
	getValues,
	variables,
	viewId,
	control,
	...rest
}, ref) => {
	const { eventCallback } = useEventContext();

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<>
			<textarea
				id={htmlId}
				className={`input-text${errors ? ' input-invalid' : ''} disabled:cursor-not-allowed`}
				data-testid="textarea"
				placeholder={placeholder}
				spellCheck
				rows={rows}
				aria-invalid={!!errors}
				aria-required={isRequired}
				autoComplete={autocomplete}
				onFocus={() => eventCallback('onFocus', { timestamp: Date.now(), elementId: label })}
				ref={ref}
				disabled={isDisabled}
				{...register(id, { onBlur: () => eventCallback('onBlur', { timestamp: Date.now(), elementId: label }) })}
				{...rest}
			/>

			{errors ? (
				<span className="input-error" data-testid="error-message">
					{errors.message}
				</span>
			) : null}

		</>
	);
};

export default forwardRef(Textarea);
