/**
 * This function removes :: characters from id's for use in dynamic id's bc css cant handle the special char
 * @param  {String} id - id, name or title coming from ION
 * @returns {String} a new string with :: removed and replaced with a -; also removes any character or whitespace (not allowed in css ids) and replaces with nothing
 */
const replaceSpecialChars = (id) => {
	if (!['string', 'number', 'boolean'].includes(typeof id)) {
		// eslint-disable-next-line no-console
		console.error(`Element id must be a string, boolean or number. Received: [${typeof id}]`);
		return id;
	}
	// force id to be a string.
	const idToUse = `${id}`;
	const replaced = idToUse?.replace(/::/g, '-').replace(/[^a-zA-Z0-9/-]|\s+/g, '');
	return replaced;
};

export default replaceSpecialChars;
