import { runMingo, unFlatten } from '@buddy-technology/ion-helpers';
/**
 * @typedef Values
 * @property {Object} [state = {}] - top level form state
 * @property {Object} [variables = {}] - the ion variables in case they're referenced
 * @property {Object} [offeringOptions = {}] - the available offeringOptions in case they're referenced
 */
/**
 * Helper function for extracting shouldRenderMobileTableCards property from a mingo calculation
 * @param  {Object | Boolean} shouldRenderMobileTableCards - shouldRenderMobileTableCards property
 * @param  {Values} [values={state:{},variables:{},offeringOptions:{}}] - Object with state, variables, and offeringOptions as top level properties
 * @returns {Boolean}
 */
const calculateShouldRenderMobileTableCards = (shouldRenderMobileTableCards, { state = {}, variables = {}, offeringOptions = {} }) => {
	if (typeof shouldRenderMobileTableCards !== 'object' || shouldRenderMobileTableCards._calculate === undefined) {
		throw new Error('shouldRenderMobileTableCards is not an object and/or does not have a _calculate property');
	}
	const { value: mingoValue, error } = runMingo(shouldRenderMobileTableCards._calculate, { ...unFlatten(state), variables, offeringOptions });
	if (error) {
		// eslint-disable-next-line no-console
		console.log(`Problem calculating mingo in shouldRenderMobileTableCards: ${error}`);
		return false;
	}
	return mingoValue;
};

export default calculateShouldRenderMobileTableCards;
