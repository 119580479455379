import { runMingo, unFlatten } from '@buddy-technology/ion-helpers';
import { useWatch } from 'react-hook-form';

const generateDynamicOptions = ({
	dynamicOptionLabels, getValues, variables, control, offeringOptions,
}) => {
	const valuesToWatch = dynamicOptionLabels?.fieldsToWatch ? dynamicOptionLabels?.fieldsToWatch.map((_label) => _label.replace(/\$/g, '').replace(/\./g, '::')) : [];

	if (dynamicOptionLabels?._calculate) {
		const state = getValues();
		const { value: mingoValue, error } = runMingo(dynamicOptionLabels._calculate, { ...unFlatten(state), variables, offeringOptions });
		if (error) {
			// eslint-disable-next-line no-console
			console.error(`Problem calculating dynamic option labels: ${error}`);
			return [];
		}

		// make sure mingoValue is an array
		if (!Array.isArray(mingoValue)) {
			if (typeof window !== 'undefined' && window.Sentry) {
				window.Sentry.withScope((scope) => {
					scope.setTags({
						rejectionType: 'generateDynamicOptions',
						mingoValue,
					});
					window.Sentry.captureException(`mingoValue should be an array, but got: ${JSON.stringify(mingoValue)}`);
				});
			}
			// eslint-disable-next-line no-console
			console.error('mingoValue should be an array, but got:', mingoValue);
		}

		const dynamicOptionsToRender = mingoValue?.reduce((acc, _item) => {
			const { value, label } = _item;
			if (label && value && label !== '' && value !== '') {
				/*
					we need to give dynamic options a new unique key when it re-renders
					this addresses a bug with react hook form that cannot update a newly generated option when a previously
					selected option disappears
				*/
				acc.push({ ..._item, key: `${value}${Math.random()}` });
			}
			return acc;
		}, []);

		// const dynamicOptionsToRender = Array.isArray(mingoValue) ? mingoValue?.reduce((acc, _item) => {
		// 	const { value, label } = _item;
		// 	if (label && value && label !== '' && value !== '') {
		// 		/*
		// 			we need to give dynamic options a new unique key when it re-renders
		// 			this addresses a bug with react hook form that cannot update a newly generated option when a previously
		// 			selected option disappears
		// 		*/
		// 		acc.push({ ..._item, key: `${value}${Math.random()}` });
		// 	}
		// 	return acc;
		// }, []) : [];

		useWatch({ control, name: valuesToWatch });
		return dynamicOptionsToRender;
	}
	return [];
};

export default generateDynamicOptions;
