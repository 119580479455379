import { useEffect, useRef } from 'react';
import {
	Form, ApplicationEditor, FormPreview,
} from '@buddy-technology/ion-to-react';

import { trackUserEvents, reportError } from '../models/analytics';
import { ACTION_ALIASES } from '../models/Dictionary';

function OfferElement({
	ion,
	stripe,
	parent,
	ionProps = {},
	stage,
}) {
	const elementRef = useRef(null);
	const heightRef = useRef(0);

	const sendMessage = (action, payload) => {
		try {
			const source = parent || window.parent || window; // need to access our parent if no source.
			source.postMessage({
				action,
				payload,
				buddy_source: 'offer-element',
				timestamp: Date.now(),
			}, '*');
		} catch (error) {
			reportError(error);
		}
	};

	useEffect(() => {
		const element = elementRef.current;
		if (!element) {
			return;
		}

		const resizeObserver = new ResizeObserver((entries) => {
			// requestAnimationFrame is used to ensure that the height is updated after the DOM has been updated, which eliminates the 'undelivered notifications' error.
			requestAnimationFrame(() => {
				entries.forEach((entry) => {
					heightRef.current = entry.contentRect.height;
					sendMessage('RESIZE', heightRef.current);
				});
			});
		});
		resizeObserver.observe(element);

		// eslint-disable-next-line consistent-return
		return () => {
			resizeObserver.unobserve(element);
		};
	}, []);

	const eventCallback = (eventType, data) => {
		sendMessage('USER_EVENT', { eventType, data });
		const { session = {} } = ionProps.data;
		const { channelUrl } = session;
		// pass along our ion id and channelUrl for tracking purposes.
		// TODO: include ion version once that is available.
		trackUserEvents(eventType, { ...data, ion: ion.id, channelUrl });
	};

	// This returns an object with all of our action functions where the keys match our action alias.
	// See the ACTION_ALIASES dictionary to see what actions are available.
	const actions = Object.entries(ACTION_ALIASES).reduce((acc, [key, value]) => {
		acc[key] = (payload) => sendMessage(value, payload);
		return acc;
	}, {});

	let RequestedComponent;

	switch (ionProps?.mode) {
		case 'APPLICATION_EDITOR':
			RequestedComponent = ApplicationEditor;
			break;
		case 'FORM_PREVIEW':
			RequestedComponent = FormPreview;
			break;
		case 'FORM':
			RequestedComponent = Form;
			break;
		default:
			RequestedComponent = Form;
			break;
	}

	return 			(
		<div ref={elementRef}>
			<RequestedComponent
				{...ionProps}
				ion={ion}
				stripe={stripe}
				stage={stage}
				eventsCallback={eventCallback}
				{...actions}
			/>
		</div>
	);
}

export default OfferElement;
