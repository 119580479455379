/* eslint-disable no-param-reassign */
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import * as themes from './styles';
import * as colorSchemes from './colors';

const allowedStripeStyles = [
	'color', 'fontFamily', 'fontSize', 'fontSmoothing', 'fontStyle', 'fontVariant', 'fontWeight', 'iconColor',
	'lineHeight', 'letterSpacing', 'textAlign', 'padding', 'textDecoration', 'textShadow', 'textTransform', ':hover',
	':focus', '::placeholder', '::selection', ':-webkit-autofill', ':disabled', '::ms-clear',
];

export const mapColors = (theme) => ({
	'--color-primary': theme.primary || '',
	'--color-secondary': theme.secondary || '',
	'--color-tertiary': theme.tertiary || '',
	'--color-positive': theme.positive || '',
	'--color-negative': theme.negative || '',
	'--color-text-primary': theme.textPrimary || '',
	'--background-primary': theme.backgroundPrimary || '',
	'--background-secondary': theme.backgroundSecondary || '',
});

/**
 * @param  {Object} theme - theme object passed. See embed-docs for structure.
 * @returns {Object} - unified styles object for emotion to build global css.
 */
export const buildStyles = (theme) => {
	const {
		baseTheme = 'base',
		palette = 'base',
		overrides = {},
	} = theme;

	const {
		styles: styleOverrides = {},
		colors: colorOverrides = {},
		webFonts: addedFonts = [],
	} = overrides;

	// cloning our theme files to not mutate objects and persist styles
	const themeToUse = (baseTheme === 'none' ? { styles: {}, webFonts: [] } : (cloneDeep(themes[baseTheme]) || {}));
	const basePalette = (palette === 'none' ? {} : (colorSchemes[palette]) || {});

	const { styles: baseStyles = {}, webFonts: baseWebFonts = [] } = themeToUse;

	const newColors = { ...basePalette, ...colorOverrides };
	const styles = merge(cloneDeep(baseStyles), cloneDeep(styleOverrides), { ':root': mapColors(newColors) });
	const webFonts = [...baseWebFonts, ...addedFonts];

	return { styles, webFonts };
};

export const buildStripeStyles = (rawTheme = {}) => {
	const { styles } = buildStyles(rawTheme);
	const { body = {} } = styles;
	const input = styles['.input-text'] || styles.input || {};
	const inputStyles = allowedStripeStyles.reduce((prev, current) => {
		if (body[current]) {
			prev[current] = body[current];
		}
		if (input[current]) {
			prev[current] = input[current];
		}
		return prev;
	}, {});
	const containerStyles = omit(input, allowedStripeStyles);
	return { containerStyles, inputStyles };
};

export const buildCreatableStyles = (rawTheme = {}) => {
	const { styles } = buildStyles(rawTheme);
	const { body = {} } = styles;
	const input = styles['.input-select'] || styles.input || {};
	const creatableStyles = merge({ ...body }, input); // spreading body here to copy the object ref. otherwise it'll mutate.
	const fontColor = body.color || input.color;
	const indicatorDisplay = creatableStyles.background?.includes('url') ? 'none' : 'block';
	delete creatableStyles.width;
	delete creatableStyles.display;
	delete creatableStyles.flexGrow;
	return {
		control: (provided) => ({ ...provided, ...creatableStyles }),
		option: (provided) => ({ ...provided, ...body }),
		// we need to manually kill some select styles (but not all!)
		container: (provided) => ({ ...provided, backgroundColor: creatableStyles.backgroundColor }),
		valueContainer: (provided) => ({ ...provided, margin: 0, padding: 0 }),
		input: (provided) => ({ ...provided, margin: 0, padding: 0 }),
		dropdownIndicator: (provided) => ({
			...provided, margin: 0, padding: 0, color: fontColor, display: indicatorDisplay,
		}),
		placeholder: (provided) => ({ ...provided, margin: 0, padding: 0 }),
		indicatorSeparator: () => ({ display: 'none' }),
	};
};
