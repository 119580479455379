import { useEffect } from 'react';
import { useDataContext } from '../context';
import logInfo from '../utils/logInfo';

const usePostMessage = ({
	src, parent, callback, hasAction,
}) => {
	const { debug } = useDataContext();
	// Because we conditionally set up a listener, we also conditionally return the cleanup function.
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		// only run the function if hasAction is true and src or parent exist.
		// useEffects should only ever return a cleanup function, so using this pattern of keeping logic in the `if`.
		if (hasAction && (src || parent)) {
			const postMessageListener = ({ data, origin, source }) => {
				let isValidMessage;
				if (src) {
					const { origin: originToListenFor } = new URL(src);
					isValidMessage = origin === originToListenFor;
				} else {
					isValidMessage = source === window.parent && data.buddy_source === 'config';
				}
				if (isValidMessage) {
					callback({ data, origin, source });
				}
				if (debug) {
					logInfo('post message received:', {
						data, origin, source, isValidMessage,
					});
				}
			};
			window.addEventListener('message', postMessageListener);
			return () => {
				window.removeEventListener('message', postMessageListener);
			};
		}
	}, [src, parent, hasAction]);
};

export default usePostMessage;
