import { calcIntervalPricing, getFieldsByQuery, PAYMENT_TERMS } from '@buddy-technology/ion-helpers';

function getPremiumByInterval(totalPremium, ion) {
	const [paymentOption] = getFieldsByQuery({ id: 'policy::paymentOption' }, ion, true);
	const terms = paymentOption?.rules?.allowedValues;
	const termsToUse = terms?.length ? terms : Object.keys(PAYMENT_TERMS);
	const paymentByInterval = termsToUse.reduce((runningValue, currentValue) => {
		const intervalPricing = calcIntervalPricing(totalPremium, currentValue);
		if (intervalPricing) {
			return { ...runningValue, [currentValue]: intervalPricing };
		}
		return runningValue;
	}, {});
	return paymentByInterval;
}

export default getPremiumByInterval;
