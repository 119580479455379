import get from 'lodash/get';
import hasFrom from './hasFrom';

/**
 * Extracts values from data object based on _from key.
 * @param {object} obj - object possibly containing '_from' keys to reference.
 * @param {object} data - the data object of which extract values from.
 * @returns {object} object matching shape of `obj` param, and with values extracted from `data` object.
 */

const extractFromData = (obj, data) => {
	if (typeof obj === 'object' && obj !== null) {
		const extractedObj = Object.entries(obj).reduce((acc, [key, value]) => {
			if (key === '_from') {
				return get(data, value);
			}
			if (hasFrom(value)) {
				acc[key] = get(data, value._from);
			} else {
				acc[key] = extractFromData(value, data);
			}
			return acc;
		}, {});
		return extractedObj;
	}
	return obj;
};

export default extractFromData;
