/**
 * This function cleans RHF values before trimming
 * @param  {Object} valuesObj - the RHF values object
 * @returns {Object} a new valuesObj with trimmed strings
 */
const trimValues = (valuesObj) => {
	const trimmed = Object.keys(valuesObj).reduce((prev, current) => {
		let value = valuesObj[current];
		if (typeof value === 'string') {
			value = value.trim();
		}
		return { ...prev, [current]: value };
	}, {});
	return trimmed;
};

export default trimValues;
