import React from 'react';
import { EventProvider, DataProvider, ThemeProvider } from '../context';
import GlobalStyles from '../components/UI/GlobalStyles';
import ApplicationEditorComponent from '../components/AppEditor';
import {
	prepareION,
} from '../utils';
import logInfo from '../utils/logInfo';

/**
 * @description A component for rendering the ION Application data that's supplied
 * @param {ApplicationEditorProps} props
 */
const ApplicationEditor = (props) => {
	const {
		ion: rawIon,
		theme = { baseTheme: 'base' },
		eventCallback = () => {},
		debug = false,
		...appEditorProps
	} = props;
	const {
		data,
		policyId,
		stage,
		subTitle,
		title,
	} = appEditorProps;
	const ion = prepareION(rawIon);
	const offeringOptions = {
		data,
		policyId,
		stage,
		subTitle,
		title,
	};

	if (debug) {
		logInfo('ENABLED');
	}

	// Key prop is set to stringified Ion id in case Ion is null. This forces the FormComponent to unmount/re-mount on an Ion update. This is what we want, otherwise, Ion data could persevere in memory (which was happening without this.)
	const key = `app-editor${ion.id}`;
	return (
		<DataProvider
			ion={ion}
			offeringOptions={offeringOptions}
			debug={debug}
		>
			<GlobalStyles theme={theme}>
				<ThemeProvider theme={theme}>
					<EventProvider eventCallback={eventCallback}>
						<ApplicationEditorComponent
							ion={ion}
							theme={theme}
							{...appEditorProps}
							key={key}
						/>
					</EventProvider>
				</ThemeProvider>
			</GlobalStyles>
		</DataProvider>
	);
};

export default ApplicationEditor;
