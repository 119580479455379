import React, { createContext, useContext, useMemo } from 'react';
import { makeCall } from '../models/api';
import createCallState from '../models/createCallState';
import { CALL_STATUSES } from '../models/dictionary';

export const CallContext = createContext();

export const CallProvider = ({ children }) => {
	const { calls } = createCallState();
	const addCall = (callObj) => {
		calls.value = [...calls.value, callObj];
	};

	const updateCall = (callId, updates) => {
		const callToUpdate = calls.value.find(({ id }) => id === callId);
		const filtered = calls.value.filter(({ id }) => id !== callId);
		const updatedCalls = { ...callToUpdate, ...updates };
		calls.value = [...filtered, updatedCalls];
	};

	const removeCall = (callId) => {
		calls.value = calls.value.filter(({ id }) => id !== callId);
	};

	const getCall = (callId) => calls.value.find(({ id }) => id === callId);

	const executeCall = async ({
		ionId,
		callId,
		partnerId,
		payload,
		stage,
	}) => {
		const existingCall = getCall(callId);

		if (existingCall?.status === CALL_STATUSES.PENDING) {
			existingCall.controller.abort();
		}

		if (existingCall && [CALL_STATUSES.RESOLVED, CALL_STATUSES.ERROR].includes(existingCall.status)) {
			removeCall(callId);
		}

		const body = { id: callId, payload };

		try {
			const controller = new AbortController();
			// update context with our call
			addCall({ id: callId, status: CALL_STATUSES.PENDING, controller });

			// pass the signal to our call in case we need to abort.
			const callParams = {
				ionId, signal: controller.signal, body, partnerId,
			};

			const res = await makeCall(callParams, stage);
			updateCall(callId, { status: CALL_STATUSES.RESOLVED, response: res });
		} catch (error) {
			if (error.name === 'AbortError') {
				updateCall(callId, { status: CALL_STATUSES.ABORTED });
				return;
			}
			updateCall(callId, { status: CALL_STATUSES.ERROR, error });
		}
	};

	const returnValue = useMemo(() => ({
		calls,
		executeCall,
		addCall,
		removeCall,
		updateCall,
	}), []);

	return (
		<CallContext.Provider value={returnValue}>
			{children}
		</CallContext.Provider>
	);
};

export const useCallContext = () => {
	const context = useContext(CallContext);
	if (!context) {
		throw new Error('useCallContext must be used within a CallProvider');
	}
	return context;
};
