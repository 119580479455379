const formatAddressAutocomplete = (results) => {
	const addressParts = results[0].address_components;
	let addressCompleteResults = {};
	addressParts.map((part) => {
		if (part.types.includes('street_number')) {
			addressCompleteResults = {
				...addressCompleteResults,
				street_number: {
					long_name: part.long_name,
					short_name: part.short_name,
				},
			};
		} else if (part.types.includes('route')) {
			addressCompleteResults = {
				...addressCompleteResults,
				route: {
					long_name: part.long_name,
					short_name: part.short_name,
				},
			};
		} else if (part.types.includes('locality')) {
			addressCompleteResults = {
				...addressCompleteResults,
				locality: {
					long_name: part.long_name,
					short_name: part.short_name,
				},
			};
		} else if (part.types.includes('administrative_area_level_1')) {
			addressCompleteResults = {
				...addressCompleteResults,
				administrative_area_level_1: {
					long_name: part.long_name,
					short_name: part.short_name,
				},
			};
		} else if (part.types.includes('postal_code')) {
			addressCompleteResults = {
				...addressCompleteResults,
				postal_code: {
					long_name: part.long_name,
					short_name: part.short_name,
				},
			};
		} else if (part.types.includes('country')) {
			addressCompleteResults = {
				...addressCompleteResults,
				country: {
					long_name: part.long_name,
					short_name: part.short_name,
				},
			};
		} else if (part.types.includes('subpremise')) {
			addressCompleteResults = {
				...addressCompleteResults,
				subpremise: {
					long_name: part.long_name,
					short_name: part.short_name,
				},
			};
		}
		return addressParts;
	});
	return addressCompleteResults;
};

export default formatAddressAutocomplete;
