import get from 'lodash/get';
import convertFieldIDtoRHF from './convertFieldIDtoRHF';
/**
	 * Gets the iframe src:
	 * If it's valid url, return it.
	 * If it's a path to a stateful value, go get it and return it.
	 * @param  {string} path
	 * @param {function} getValues
	 * @returns {string | undefined} the iframe src if path is valid, otherwise undefined.
	 */
const getIframeUrl = (path, getValues) => {
	if (typeof path !== 'string') {
		return undefined;
	}
	try {
		const url = new URL(path);
		return url.href;
	} catch (error) {
		// if not valid url, it's probably a reference.
		const fieldPath = convertFieldIDtoRHF(path);
		const ref = get(getValues(), fieldPath); // returns null
		return ref;
	}
};

export default getIframeUrl;
