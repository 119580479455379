import { useSignal } from '@preact/signals-react';

// signals are awesome and don't trigger unnecessary renders down the component tree.
// check the docs for preact's signals here: https://preactjs.com/guide/v10/signals/

function createCallState() {
	const calls = useSignal([]);
	return { calls };
}

export default createCallState;
