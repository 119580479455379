import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	getFields,
	getIonValues,
	ION_TO_JOI,
} from '@buddy-technology/ion-helpers';
import GlobalStyles from '../components/UI/GlobalStyles';
import { RenderedView, IF } from '../components/helpers';
import { Button } from '../components/UI';
import { getStepThroughViews, prepareION } from '../utils';
import { useSetDynamicValues } from '../hooks';
import {
	CallProvider,
	EventProvider,
	DataProvider,
	ThemeProvider,
} from '../context';

/**
 * FieldPreviewer returns a react component displaying a field or group of fields.
 * @param {Object} props
 * @param {Object} props.ion - a parent ION
 * @param {Object[]} [props.showOnly ] - an array of whitelisted fields that you want to preview
 * @param {boolean} [props.showValidateButton = false] - toggles the validate button
 * @param {Object} [props.theme] - an object to override the theme settings
 * @returns React Component
 */
function FormPreviewComponent({
	ion,
	// Undefined or an array of field IDs to only display
	showOnly,
	data,
	showValidateButton = false,
	eventCallback,
	theme,
	placesApiKey,
}) {
	// The current model shows the whole ion unless specific fields are instructed
	// to be shown using showOnly. If we want the opposite, nothing shown unless
	// instructed change that here.
	const fields = getFields(ion);
	const { views } = ion.application;

	// if showOnly isn't an array, make it one
	const allowed = Array.isArray(showOnly) ? showOnly : [showOnly];

	const displayedFields = showOnly?.length // if showOnly is falsy/empty, render all fields. Otherwise, render only the allowed ones.
		? fields.filter((field) => (allowed.includes(field.id)))
		: fields;

	const allViews = getStepThroughViews({ fields: displayedFields, views, includeOnly: allowed });
	const displayedViews = allViews.filter((view) => view.fields);

	// Get starting values or defaults to initialize the form.
	const fieldValues = getIonValues(displayedFields, data);

	// Filter out reference fields so ITJ doesn't validate them.
	const schemaFields = displayedFields.filter((input) => input.uiDisplay.element !== 'REFERENCE');
	const schema = ION_TO_JOI(schemaFields);

	const {
		register: rhfRegister,
		control,
		handleSubmit,
		getValues,
		getFieldState,
		setValue,
		trigger,
		clearErrors,
		watch,
		formState: {
			errors,
		},
	} = useForm({
		mode: 'onTouched',
		defaultValues: fieldValues,
		resolver: joiResolver(
			schema.unknown(true),
			{
				abortEarly: false,
				errors: {
					wrap: {
						label: '',
						array: '"',
					},
				},
			},
		),
	});

	const updateAppState = useSetDynamicValues({
		getValues, getFieldState, setValue, variables: ion?.variables, flatApplication: displayedFields,
	});

	const displayedFieldIds = displayedFields.map(({ id }) => id);

	const register = (name, options = {}) => (
		rhfRegister(name, {
			...options,
			onChange: (e) => {
				if (options.onChange) {
					options.onChange(e);
				}
				updateAppState();
			},
		})
	);

	return (
		<ThemeProvider theme={theme}>
			<EventProvider eventCallback={eventCallback}>
				<CallProvider>
					<form onSubmit={handleSubmit(() => (true))}>
						{ displayedViews.map(({ id, ...props }) => (
							<RenderedView
								id={id}
								register={register}
								control={control}
								Controller={Controller}
								displayedFields={displayedFieldIds}
								trigger={trigger}
								errors={errors}
								setValue={setValue}
								getValues={getValues}
								clearErrors={clearErrors}
								getFieldState={getFieldState}
								flatApplication={fields}
								watch={watch}
								variables={ion?.variables}
								key={id}
								updateAppState={updateAppState}
								showNav={false}
								// we want navigate and updateCurrentView as required props in most cases.
								// passing in empty func here to not break tests.
								navigate={() => {}}
								updateCurrentView={() => {}}
								placesApiKey={placesApiKey}
								{...props}
							/>
						))}
						<IF condition={showValidateButton}>
							<Button
								label="Validate"
								type="submit"
							/>
							<Button
								className="ml-2"
								label="Clear Errors"
								variant="secondary"
								onClick={() => {
									clearErrors();
								}}
							/>
						</IF>
					</form>
				</CallProvider>
			</EventProvider>
		</ThemeProvider>
	);
}

// TODO: Pull this out into a wrapper component.
// Key prop is set to stringified ION id in case ION is null. This forces the FormComponent to unmount/re-mount on an ION update. This is what we want, otherwise, ION data could persevere in memory (which was happening without this.)
const FormPreview = ({
	theme,
	ion: rawIon,
	showOnly,
	data,
	showValidateButton,
	eventCallback = () => {},
}) => {
	const ion = prepareION(rawIon);
	// this object holds our incoming props in order to run mingo queries where needed.
	const offeringOptions = {
		data,
	};
	return (
		<GlobalStyles theme={theme}>
			<DataProvider ion={ion} offeringOptions={offeringOptions}>
				<FormPreviewComponent
					ion={ion}
					showOnly={showOnly}
					data={data}
					showValidateButton={showValidateButton}
					theme={{ baseTheme: 'base' }}
					eventCallback={eventCallback}
					key={`form-preview${ion.id}`}
				/>
			</DataProvider>
		</GlobalStyles>
	);
};

export default FormPreview;
