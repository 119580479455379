import { useWatch } from 'react-hook-form';
import { ION_TO_JOI } from '@buddy-technology/ion-helpers';

function getShouldRequire(fieldObject, control) {
	const { rules, isRequired: topLevelIsRequired, ...field } = fieldObject;
	const { conditions, isRequired } = rules;
	if (conditions) {
		const requireConditions = conditions.filter(({ then, otherwise }) => then?.isRequired || otherwise?.isRequired);
		// key names have already been converted to RHF format at ITR instantiation. No need to reconvert.
		const keysToWatch = requireConditions.map(({ condition }) => condition.key);
		field.rules = { conditions: requireConditions };
		const schema = ION_TO_JOI([field]);
		// need to subscribe to our referenced inputs so we can dynamically update when those values change
		const watchedValues = useWatch({ control, name: keysToWatch });
		// create a schema validation object. keysToWatch is an array of field ids.
		const objectToValidate = keysToWatch.reduce((acc, curr, index) => ({ ...acc, [curr]: watchedValues[index] }), {});

		// need to allowUnknown here to allow since our referenced fields aren't part of the mini schema. Guarantees that if we get an error the only error is our require error.
		const { error } = schema.validate(objectToValidate, { allowUnknown: true, abortEarly: false });
		if (error) {
			return true;
		}
	}
	// Don't rely on it being falsy, only return this if it was passed.
	if (typeof isRequired === 'boolean') {
		return isRequired;
	}
	// since this is our last check, we can coerce to a boolean and return.
	return !!(topLevelIsRequired);
}

export default getShouldRequire;
