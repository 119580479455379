import React from 'react';
import { useWatch } from 'react-hook-form';

import RenderedArray from './RenderedArray';
import RenderedField from './RenderedField';
import OfferOptionsControls from '../OfferOptionsControls';
import Markdown from '../UI/Markdown';
import { useDataContext } from '../../context';
import { calculateIsHidden, convertFieldIDtoRHF, getFieldPaths } from '../../utils';

function ViewContent({
	content,
	displayedFields,
	getValues,
	control,
	index,
	viewId,
	tabPanels,
	...rest
}) {
	const { ion: { variables }, offeringOptions } = useDataContext();

	const {
		fields,
		text,
		tabField,
		offerOptions,
		id: contentId = '',
		position,
		positionIndex,
		isHidden,
		shouldRenderMobileTableCards = false,
	} = content;

	const useTabField = tabField && content.type === 'TABS' && tabField.uiDisplay.element === 'RADIO';

	const pathsToWatch = getFieldPaths(isHidden).map((path) => convertFieldIDtoRHF(path));
	useWatch({ control, name: pathsToWatch });
	const shouldDisplay = !calculateIsHidden(isHidden, { rawState: getValues(), offeringOptions, variables });
	if (!shouldDisplay) {
		return null;
	}
	const indexToUse = Number.isInteger(positionIndex) ? positionIndex : index;
	if (fields && !useTabField) {
		const fieldsToRender = fields.filter(({ id }) => displayedFields.includes(id))
			.map((fieldProps) => {
				const { id, type, uiDisplay } = fieldProps;
				if (type === 'ARRAY' && uiDisplay?.element !== 'CHECKBOX') {
					return (
						<RenderedArray
							key={`rendered-${id}`}
							getValues={getValues}
							control={control}
							viewId={viewId}
							{...rest}
							{...fieldProps}
						/>
					);
				}
				return (
					<RenderedField
						key={id}
						getValues={getValues}
						control={control}
						viewId={viewId}
						{...rest}
						{...fieldProps}
					/>
				);
			});
		return (
			<div id={`${viewId}-content-container-${index}`} data-id={contentId}>
				{fieldsToRender}
			</div>
		);
	}
	if (text) {
		return (
			<Markdown
				getValues={getValues}
				variables={variables}
				// index is guaranteed to be stable here since it comes from static ion.
				// some IONs are using the same markdown in multiple places on the same view, so we need this to identify.
				// eslint-disable-next-line react/no-array-index-key
				key={`${text}-${index}`}
				text={text}
				index={indexToUse}
				position={position}
				viewId={viewId}
				control={control}
				contentId={contentId}
				shouldRenderMobileTableCards={shouldRenderMobileTableCards}
			/>
		);
	}
	if (useTabField) {
		return (
			<div id={`${viewId}-content-container-${index}`} data-id={contentId}>
				<RenderedField
					key={tabField.id}
					getValues={getValues}
					control={control}
					viewId={viewId}
					tabPanels={tabPanels}
					{...rest}
					{...tabField}
				/>
			</div>
		);
	}
	if (offerOptions) {
		return (
			<OfferOptionsControls
				getValues={getValues}
				variables={variables}
				viewData={{ options: offerOptions }} // TODO: SIMPLIFY THIS OBJECT
				viewId={viewId}
				control={control}
				{...rest}
				{...offerOptions}
			/>
		);
	}
}

export default ViewContent;
