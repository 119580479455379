import React, { useRef } from 'react';
import { ulid } from 'ulid';
import { joiResolver } from '@hookform/resolvers/joi';
import { Elements } from '@stripe/react-stripe-js';
import { flatten } from 'flat';
import { ION_TO_JOI, getFields } from '@buddy-technology/ion-helpers';
import InnerForm from './InnerForm';
import { GlobalStyles } from '../components/UI';
import { Checkout } from '../components';
import { EventProvider, DataProvider, ThemeProvider } from '../context';
import {
	filterConditionalFieldsOrViews,
	prepareION,
	getQuoteRequirements,
} from '../utils';
import getInitialIonValues from '../utils/getInitialIonValues';
import logInfo from '../utils/logInfo';
import { VIEW_TYPES, ION_VIEW_TYPES, INVALID_DISPLAY_TYPES } from '../models/dictionary';
import getFieldErrors from '../utils/getFieldErrors';

const StripedCheckout = ({ stripe, ...rest }) => (
	<Elements stripe={stripe}>
		<Checkout {...rest} />
	</Elements>
);
// todo: JSDOC
const DEFAULTS = {
	VIEW_TYPE: VIEW_TYPES.PAGINATED,
	DATA: {},
	DEBUG: false,
	INCLUDE_CHECKOUT: true,
	LOGO_OVERRIDE: {
		url: 'https://buddy.insure',
		alt: 'Powered by Buddy',
		src: 'https://buddy-img.s3.amazonaws.com/powered-by/powered_by_red.svg',
	},
	STAGE: 'PRODUCTION',
	ON_OPT_IN: () => {},
	ON_OPT_OUT: () => {},
	EVENTS_CALL_BACK: (type, data) => {},
	ON_CUSTOM_MESSAGE: () => {},
};

/**
 * Form
 *
 * The main component for rendering Insurance Offering Applications and it's details
 *
 * @param {Object} props
 * @param {Object} props.ion - the parent ion to render
 * @param {string} props.partnerID - ID of the partner affiliating the offering
 * @param {Promise<Token>} [props.stripe] - The returned promise from loadStripe
 * @param {string} [props.viewType='paginated'] - How the form should display itself
 * @param {Object} [props.data={}] - data to pre-populated the form with
 * @param {boolean} [props.includeCheckout=true] - whether or not to display the checkout
 * @param {callback} [props.onAddToCart] - a callback for when users opt in to an offer-only offer
 * @deprecated - use onOptIn instead
 * @param {callback} [props.onRemoveFromCart] - a callback for when users opt out to an offer-only offer
 * @deprecated - use onOptOut instead
 * @param {callback} [props.onOptIn] - a callback for when users opt in to an offer-only offer
 * @param {callback} [props.onOptOut] - a callback for when users opt out to an offer-only offer
 * @param {Object} [props.theme] - an object to override the theme settings
 * @param {String} [stage = "staging"] - stage for which endpoints to use: 'development', 'staging', 'production'
 */
const FormComponent = ({
	ion: rawIon,
	partnerID: partnerId,
	stripe,
	viewType = DEFAULTS.VIEW_TYPE,
	data = DEFAULTS.DATA,
	debug = DEFAULTS.DEBUG,
	logoOverride: rawLogoOverride = {}, // default to empty object bc we are spreading default properties into it below
	includeCheckout = DEFAULTS.INCLUDE_CHECKOUT,
	onOptIn = DEFAULTS.ON_OPT_IN,
	onOptOut = DEFAULTS.ON_OPT_OUT,
	onCustomMessage = DEFAULTS.ON_CUSTOM_MESSAGE,
	theme,
	stage = DEFAULTS.STAGE,
	eventsCallback = DEFAULTS.EVENTS_CALL_BACK,
	placesApiKey,
}) => {
	if (!rawIon) {
		throw new Error('ION is missing.');
	}
	const sessionIdRef = useRef(ulid());
	const isFirstLoadRef = useRef(true);

	if (debug) {
		logInfo('ENABLED');
	}

	const logoOverride = { ...DEFAULTS.LOGO_OVERRIDE };
	if (typeof rawLogoOverride === 'object') {
		Object.assign(logoOverride, rawLogoOverride);
	}
	if (!partnerId) {
		// eslint-disable-next-line no-console
		console.error('partner id is required for quoting and purchasing!');
	}

	// create ion-derived values.
	const ion = prepareION(rawIon);
	const { views } = ion.application;

	const {
		checkoutViewInfo,
		offerOnlyViewData,
		unSortedStepThroughViews,
	} = views.reduce((acc, view) => {
		const { id, type } = view;
		// need to support legacy implementations where checkout and offer only relied on view id.
		if ([id?.toUpperCase(), type].includes(ION_VIEW_TYPES.CHECKOUT)) {
			acc.checkoutViewInfo = view;
			acc.unSortedStepThroughViews.push(view); // we still want to add the checkout view to the stepThroughViews
			return acc;
		}

		if (id === VIEW_TYPES.OFFER_ONLY || type === ION_VIEW_TYPES.OFFER_ONLY) {
			acc.offerOnlyViewData = view;
			return acc;
		}

		// if we haven't found the checkout or offer only view, add it to the stepThroughViews
		acc.unSortedStepThroughViews.push(view);
		return acc;
	}, {
		checkoutViewInfo: null,
		offerOnlyViewData: null,
		unSortedStepThroughViews: [],
	});

	const stepThroughViews = unSortedStepThroughViews.sort((a, b) => a.order - b.order);
	const	quoteFields = getQuoteRequirements(ion);

	// default offerOnlyViewData.invalidFieldDisplay to 'PAGINATED' if not set
	if (offerOnlyViewData) {
		offerOnlyViewData.invalidFieldDisplay = offerOnlyViewData.invalidFieldDisplay || INVALID_DISPLAY_TYPES.PAGINATED;
	}

	// Flatten the Ion's Application to make it easier for RHF and ITR to consume.
	const flatApplication = getFields(ion, true);

	// filtered comes without hidden fields or reference elements
	const filteredApplication = flatApplication.filter(({ uiDisplay }) => (uiDisplay.element !== 'REFERENCE' && uiDisplay.isHidden !== true));

	const ionValues = getInitialIonValues({
		flatApplication,
		incomingData: data,
		context: {
			variables: ion.variables,
			...data,
			offeringOptions: {
				partnerId,
				viewType,
				data,
				includeCheckout,
			},
		},
	});

	const schema = ION_TO_JOI(filteredApplication);
	const resolver = joiResolver(
		schema,
		{
			allowUnknown: true,
			abortEarly: false,
			errors: {
				wrap: {
					label: '',
					array: '"',
				},
			},
		},
	);

	const { fieldErrors, invalidFields } = getFieldErrors({
		incomingValues: ionValues,
		schema,
		debug,
	});

	// this object holds our incoming props in order to run mingo queries where needed.
	const offeringOptions = {
		partnerId,
		viewType,
		data,
		fieldErrors,
		invalidFields,
		includeCheckout,
	};

	const getInitialView = () => {
		const visibleViews = filterConditionalFieldsOrViews(
			stepThroughViews,
			ionValues,
			ion?.variables,
			offeringOptions,
		);

		if (!visibleViews.length) {
			if (viewType === VIEW_TYPES.OFFER_ONLY) {
				// if there are no visible views, but the viewType is offerOnly, return the offerOnlyViewData.id
				return offerOnlyViewData.id;
			}
			// if there are no visible views, we cannot render and must throw an error to alert the dev.
			throw new Error('No visible views found. Please check your ion configuration.');
		}

		return visibleViews[0].id;
	};

	// check for the first view that's not hidden to set initialView
	const initialView = getInitialView();

	// We're running this outside of a useEffect because we need it to run as soon as the form is rendered.
	// useEffects run after the first render, and not in the order we'd expect.
	if (isFirstLoadRef.current) {
		const incomingDataFieldIds = Object.keys(flatten(data, { safe: true }));
		eventsCallback('onOfferElementLoad', {
			timestamp: Date.now(),
			partnerId,
			incomingDataFieldIds,
		});
		isFirstLoadRef.current = false;
	}

	return (
		<ThemeProvider theme={theme}>
			<DataProvider
				ion={ion}
				offeringOptions={offeringOptions}
				sessionId={sessionIdRef.current}
				debug={debug}
			>
				<EventProvider
					eventCallback={eventsCallback}
					viewType={viewType}
					partnerId={partnerId}
					onCustomMessage={onCustomMessage}
					onOptIn={onOptIn}
					onOptOut={onOptOut}
				>
					<InnerForm
						checkoutViewInfo={checkoutViewInfo}
						includeCheckout={includeCheckout}
						initialView={initialView}
						ion={ion}
						ionValues={ionValues}
						filteredApplication={filteredApplication}
						flatApplication={flatApplication}
						logoOverride={logoOverride}
						offerOnlyViewData={offerOnlyViewData}
						onOptIn={onOptIn}
						onOptOut={onOptOut}
						partnerId={partnerId}
						quoteFields={quoteFields}
						resolver={resolver}
						stage={stage}
						stepThroughViews={stepThroughViews}
						stripe={stripe}
						StripedCheckout={StripedCheckout}
						views={views}
						viewType={viewType}
						placesApiKey={placesApiKey}
						theme={theme}
					/>
				</EventProvider>
			</DataProvider>
		</ThemeProvider>
	);
};

// TODO: Pull this out into a wrapper component.
// Key prop is set to stringified ION id in case ION is null. This forces the FormComponent to unmount/re-mount on an ION update. This is what we want, otherwise, ION data could persevere in memory (which was happening without this.)
const Form = ({
	theme = { baseTheme: 'base' }, ion, ...rest
}) => (
	<GlobalStyles theme={theme}>
		<FormComponent ion={ion} theme={theme} {...rest} key={`form-${ion.id}`} />
	</GlobalStyles>
);

export default Form;
