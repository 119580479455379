import isPlainObject from 'lodash/isPlainObject';
import { runMingo, unFlatten } from '@buddy-technology/ion-helpers';
/**
 * @typedef Values
 * @property {Object} [rawState = {}] - top level form state
  * @property {Object} [variables = {}] - the ion variables in case they're referenced
 */
/**
 * Helper function for extracting isHidden property from a mingo calculation
 * @param  {Object | Boolean} isHidden - isHidden property
 * @param  {Values} [values={rawState:{},variables:{},offeringOptions:{}}] - Object with rawState, variables, and offeringOptions as top level properties
 * @returns {Boolean}
 */
const calculateIsHidden = (isHidden, { rawState = {}, variables = {}, offeringOptions = {} }) => {
	if (typeof isHidden === 'boolean') {
		return isHidden;
	}
	const formState = unFlatten(rawState);
	if (isPlainObject(isHidden)) {
		const { _calculate: script } = isHidden;
		if (typeof script === 'string') {
			throw new Error('_calculate script is a string. Did you forget to parse the ION?');
		}
		const { value: shouldHide } = runMingo(script, { ...formState, variables, offeringOptions }); // if there's an error, shouldHide will be falsy.
		return shouldHide;
	}
	// should never get here.
	return isHidden;
};

export default calculateIsHidden;
