import React, { forwardRef, useEffect } from 'react';
import { useEventContext, useDataContext } from '../../context';
import { replaceSpecialChars } from '../../utils';
import generateDynamicOptions from '../../utils/generateDynamicOptions';
/**
 * @typedef  {Object} RadioInputProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input
 * @property {Array<{ label: string, value: string }>} optionLabels - An array of input options
 * @property {Function} register - RHF method for initializing a field
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * Pass through html params (aria-*, onClick, etc) in addition to the params below.
 * @description A single choice selector built around the HTML Input type radio element.
 * @param {RadioInputProps} props
 * @param  {Object} [ref]
 */
const RadioInput = ({
	id,
	htmlId,
	label = null,
	optionLabels = [],
	dynamicOptionLabels,
	register,
	errors,
	getValues,
	trigger,
	isEndorsable,
	isRequired,
	isDisabled,
	helpText,
	viewId,
	control,
	...rest
}, ref) => {
	const { eventCallback } = useEventContext();
	const { ion: { variables }, offeringOptions } = useDataContext();

	const dynamicOptionsToRender = generateDynamicOptions({
		dynamicOptionLabels, getValues, variables, control, offeringOptions,
	}) || [];

	const optionsToRender = dynamicOptionsToRender.length ? dynamicOptionsToRender : optionLabels;

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<>
			<div>
				<fieldset>
					<legend className="sr-only">{label}</legend>
					<div id={`${htmlId}-radio-button-wrapper`} className="radio-button-wrapper">
						{optionsToRender.map((option) => (
							<div key={option.key || option.value} className="radio-button-option-wrapper">
								<input
									id={`${htmlId}-${replaceSpecialChars(option.value)}`}
									name={id}
									className="radio-button form-radio disabled:cursor-not-allowed"
									data-testid="radio"
									type="radio"
									disabled={isDisabled}
									ref={ref}
									value={option.value}
									// Safari doesn't focus radio buttons, but to ensure we don't overwrite user manipulated values, we need the buttons to focus.
									onClick={(e) => e.target.focus()}
									{...register(id, {
										onChange: (e) => {
											trigger(id);
											eventCallback('onRadioSelection', {
												timestamp: Date.now(), elementId: label, value: e.target.value,
											});
										},
									})}
									tabIndex={0}
									{...rest}
								/>

								<label
									htmlFor={`${htmlId}-${replaceSpecialChars(option.value)}`}
									className={`radio-button-label ${errors ? 'input-invalid' : ''}`}
								>
									{option.label}
								</label>
							</div>
						))}
					</div>
				</fieldset>
			</div>

			{errors ? (
				<p className={`${errors ? 'input-error' : ''}`} data-testid="error-message">
					{errors.message}
				</p>
			) : null}

		</>
	);
};

export default forwardRef(RadioInput);
