/**
 * Recursively extracts field paths from an object.
 *
 * @param {Object|string} obj - The object or string to extract field paths from.
 * @param {string} [path=''] - The current path (used internally for recursion).
 * @returns {Array<string>} - An array of field paths.
 */
function getFieldPaths(obj, path = '') {
	if (typeof obj === 'string' && obj.startsWith('$') && obj.includes('.')) {
		return [obj.slice(1)]; // Remove the '$' and add to the result array
	}
	// arrays and objects
	if (obj && typeof obj === 'object') {
		return Object.entries(obj).reduce((result, [key, value]) => {
			const subResult = getFieldPaths(value);
			return result.concat(subResult);
		}, []);
	}

	return [];
}

export default getFieldPaths;
