import React from 'react';
import IF from '../helpers/IF';

const Card = ({ children, footer }) => (
	<div className="card">
		{children}
		<IF condition={footer}>
			<div className="">
				{footer}
			</div>
		</IF>
	</div>
);

export default Card;
