import uniq from 'lodash/uniq';

/**
 * Extracts unique field keys to watch from a list of items.
 *
 * @param {Array<Object>} [items=[]] - The list of items to process.
 * @returns {Array<string>} - An array of unique field keys to watch.
 */
const getFieldsToWatch = (items = []) => uniq(items.reduce((acc, current = {}) => {
	const { rules: { conditions } = {} } = current;
	if (conditions) {
		const ids = conditions.map(({ condition }) => condition?.key);
		return [...acc, ...ids];
	}
	return acc;
}, []));

/**
 * Removes watched fields from the form values.
 *
 * @param {Object} formValues - The original form values from react-hook-form state.
 * @param {Object} watchedFields - The fields to be removed from the form values.
 * @returns {Object} - The new form values with the watched fields removed.
 */
const stripWatchedFieldsFromForm = (formValues, watchedFields) => {
	const fieldKeys = Object.keys(watchedFields);
	const newFormValues = Object.entries(formValues).reduce(
		(acc, [key, value]) => {
			if (!fieldKeys.includes(key)) {
				acc[key] = value;
			}
			return acc;
		},
		{},
	);
	return newFormValues;
};

export {
	getFieldsToWatch,
	stripWatchedFieldsFromForm,
};
