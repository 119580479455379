/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useWatch } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
	getDynoMark,
	convertFieldIDtoRHF,
	transformUri,
	calculateShouldRenderMobileTableCards,
} from '../../utils';
import { useThemeContext, useDataContext } from '../../context';
import markdownComponents from './markdownComponents';
import MarkdownTableToCards from './MarkdownTableToCards';

const Container = ({
	pos, children, id, theme, contentId,
}) => {
	const isThemeNone = theme?.baseTheme === 'none';

	if (pos === 'LABEL-DYNOMARK') { // all other instances of Markdown component have position labels 'BEFORE' 'AFTER' 'BELOW_NAV' etc. ONLY InputLabel is using 'LABEL-DYNOMARK' so we can make sure we support existing/legacy use of the component
		return <span id={id} data-id={contentId}>{children}</span>;
	}
	return (
		<div id={id} data-id={contentId} className={`${isThemeNone ? '' : 'prose max-w-none prose-base'}`}>
			{children}
		</div>
	);
};

const TableAsCardsWithProps = (contentId) => (props) => <MarkdownTableToCards {...props} contentId={contentId} />;

/**
 * @typedef  {Object} MarkDownProps
 * @property {string} text
 * @property {Object | Boolean} isHidden - either a boolean or mingo calculation that returns a boolean to dictate when to hide/display the markup.
 * @property {Function} getValues - react-hook-form method for getting form values
 * @property {Object} variables - the ion variables
 * @property {Number} index - the index of the markdown; used to dynamically generate id
 * @property {string} position - the position where content should display; used to dynamically generate id
 * @property {Number} viewId - the id of the parent view; used to dynamically generate id
 * @property {Number} contentId - id assigned to the field or text inside of view's content block
 * @property {Object | Boolean} shouldRenderMobileTableCards - either a boolean or mingo calculation that returns a boolean to dictate when to render a table as cards on mobile (768px or less)
 */

/**
 * @description A Markdown converter component. Takes markdown and renders html on the dom.
 * @param {MarkDownProps} props
 */

function Markdown({
	text,
	getValues,
	index,
	position: positionEnum,
	viewId,
	control,
	contentId,
	shouldRenderMobileTableCards,
}) {
	const { ion: { variables }, offeringOptions } = useDataContext();
	const state = getValues();
	let processedText = getDynoMark(text, state, variables, offeringOptions);
	const theme = useThemeContext();

	// watch for values that are generated via Dynomark
	const dynoMarkValues = text.match(/\{\{(.*?)\}\}/g);
	// exclude variable values; we don't necessarily need to do this but theres no sense in running them through the conversion
	const excludeVariables = dynoMarkValues?.filter((item) => !item.match(/variables./gi));

	const dynoMarkValuesToWatch = excludeVariables?.map((item) => convertFieldIDtoRHF(item))
		.map((item) => item.replace(/[{}]/g, ''));

	useWatch({ control, name: dynoMarkValuesToWatch || [] });

	// legacy support for css that relies on the position being part of container id.
	let position;

	switch (positionEnum) {
		case 'BEFORE_FIELDS':
			position = 'BEFORE';
			break;
		case 'AFTER_FIELDS':
			position = 'AFTER';
			break;
		case 'BELOW_NAV':
			position = 'BELOW_NAV';
			break;
		case 'LABEL-DYNOMARK':
			position = 'LABEL-DYNOMARK';
			break;
		default:
			// leave undefined as default.
			break;
	}

	const isLabel = position === 'LABEL-DYNOMARK';

	let componentsToUse = markdownComponents;

	if (isLabel) {
		componentsToUse = { ...markdownComponents, p: 'span', img: 'span' }; // this simply replaces the img tag with a span so it's still in the DOM just not visible :/
		processedText = processedText.replace(/#/g, ''); // reassigning h1-h6 tag above removes the opening # and leaves the closing one behind; this is the most efficient way to account for header tags
		// other block level elements (newline (\n), blockquote (>), - (ul), 1. (ol), horiz rule (---), tables) do not work;
		// bold, italics, strikethrough, links, `` (code) work as expected
	}

	const isRenderMobileTableBooleanTrue = shouldRenderMobileTableCards === true;
	const isRenderMobileTableCalculateTrue = typeof shouldRenderMobileTableCards === 'object' && calculateShouldRenderMobileTableCards(shouldRenderMobileTableCards, { variables, state, offeringOptions }) === true;

	if (isRenderMobileTableBooleanTrue || isRenderMobileTableCalculateTrue) {
		componentsToUse = { ...markdownComponents, table: TableAsCardsWithProps(contentId) };
	}

	return (
		<Container
			pos={position}
			id={`${viewId}-content-container${position ? `-${position}` : ''}-${index}`}
			className="content-container"
			theme={theme}
			contentId={contentId}
		>
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				components={componentsToUse}
				transformLinkUri={transformUri}
			>
				{processedText || null}
			</ReactMarkdown>
		</Container>
	);
}

export default Markdown;
