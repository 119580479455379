import { RENDERED_VIEW_TYPES } from '../models/dictionary';
/**
 * @function getMiddleViews
 * @description Quick helper function for filtering out checkout and post checkout views.
 * @param  {array} _views array of view objects
 * @returns  {array} array of view objects excluding checkout and thank yous
 */
const getMiddleViews = (_views) => _views.filter(({ id, type }) => (
	![id, type?.toUpperCase()].includes(RENDERED_VIEW_TYPES.CHECKOUT)
		&& type?.toUpperCase() !== RENDERED_VIEW_TYPES.POST_CHECKOUT
));

export default getMiddleViews;
