import React from 'react';
import RenderedArray from './helpers/RenderedArray';
import RenderedField from './helpers/RenderedField';
import isArrayElement from '../utils/isArrayElement';

function Field({ field, ...props }) {
	if (isArrayElement(field)) {
		return (
			<RenderedArray
				{...props}
				{...field}
			/>
		);
	}
	return (
		<RenderedField
			{...props}
			{...field}
		/>
	);
}

export default Field;
