const formatAndReplaceAddressAutocompleteValues = (results, setValue) => {
	if (results) {
		const updatedResultsWithMingo = Object.entries(results).forEach(([key, value]) => {
			const currRenamedKey = key.replace(/\./g, '::');
			setValue(currRenamedKey, value);
		});
		return updatedResultsWithMingo;
	}
	const updatedResults = Object.entries(results).forEach(([key, value]) => {
		const currRenamedKey = key.replace(/\./g, '::');
		setValue(currRenamedKey, value);
	});
	return updatedResults;
};

export default formatAndReplaceAddressAutocompleteValues;
