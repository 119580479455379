const base = {
	primary: '#000000',
	secondary: '#254E70',
	tertiary: '#848484',
	negative: '#e45b78',
	positive: '#A3D9B1',
	textPrimary: '#000',
	textSecondary: '848484',
	backgroundPrimary: '#fff',
	backgroundSecondary: '#7d8387',
};

const buddy = {
	primary: '#DC5648',
	secondary: '#0A242D99',
	tertiary: '#46460E33',
	negative: '#D62719',
	positive: '#A3D9B1',
	textPrimary: '#0A242D',
	textSecondary: '#0A242D99',
	backgroundPrimary: 'linear-gradient(#D6F1F2, #F8F6D2)',
	backgroundSecondary: '#FBF9EF',
};

export { buddy, base };
