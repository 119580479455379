import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { ION_TO_JOI } from '@buddy-technology/ion-helpers';
import { useDeepCompareEffect } from 'react-use';
import { filterConditionalFieldsOrViews } from '../../../utils';
import { useDataContext } from '../../../context';
import RenderedField from '../RenderedField';
import IF from '../IF';
import { Button } from '../../UI';
import { stripWatchedFieldsFromForm } from './util';

const EditView = ({
	items = [],
	onSave,
	exit,
	groupValues = {},
	watchedFields = {},
	stateValues = {},
	isEndorsable,
	isEditorMode,
	parent,
	fieldProps = {},
}) => {
	const { ion: { variables }, offeringOptions } = useDataContext();

	// This only fires after a successful handleSubmit validation
	const handleSave = (formValues) => {
		const strippedFormValues = stripWatchedFieldsFromForm(formValues, watchedFields);
		onSave(strippedFormValues);
		exit();
	};

	const schema = ION_TO_JOI(items);

	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		trigger,
		getFieldState,
		reset,
		formState: {
			errors,
		},
	} = useForm({
		mode: 'onTouched',
		defaultValues: { ...watchedFields, ...groupValues },
		resolver: joiResolver(
			schema,
			{
				allowUnknown: true,
				abortEarly: false,
				errors: {
					wrap: {
						label: '',
						array: '"',
					},
				},
			},
		),
	});

	useDeepCompareEffect(() => {
		reset({ ...watchedFields, ...groupValues });
		// We should only reset the form when our stateValues change, not groupValues.
		// groupValue update on save, and are used for pre-filling fields when users click edit.
		// resetting when groupValues change cause unexpected behavior.
	}, [watchedFields]);

	const filteredItems = filterConditionalFieldsOrViews(items, getValues(), variables, offeringOptions);

	// We need to be able to reference the entire state of the app, not just the array items.
	const getAllStateValues = () => ({ ...getValues(), ...stateValues });

	return (
		<div className="array-editor-container" id={`${parent}-array-editor-container`}>
			{filteredItems?.map(({ uiDisplay, id, ...rest }) => (
				<RenderedField
					{...fieldProps}
					id={id}
					key={id}
					register={register}
					control={control}
					Controller={Controller}
					getValues={getAllStateValues}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					uiDisplay={uiDisplay}
					isEditorMode={isEditorMode}
					getFieldState={getFieldState}
					{...rest}
				/>
			))}
			<div className="array-editor-buttons-container">
				<Button
					variant="base"
					label={(!isEndorsable) ? 'Back' : 'Cancel'}
					className="array-editor-cancel-button"
					onClick={exit}
				/>
				{/* Always show if not in editor mode | Otherwise, only show in Editor mode if prop is endorsable */}
				<IF condition={!isEditorMode || (isEditorMode && isEndorsable)}>
					<Button
						className="array-editor-save-button"
						onClick={handleSubmit(handleSave)}
						label="Save"
					/>
				</IF>
			</div>
		</div>
	);
};

export default EditView;
