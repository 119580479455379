import { unFlatten } from '@buddy-technology/ion-helpers';
import logInfo from './logInfo';

/**
 * Validates incoming values against a schema and returns a structured object of field errors.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.incomingValues - The values to validate.
 * @param {Object} params.schema - The validation schema.
 * @returns {Object} - An object containing invalid field IDs and a structured object of field errors.
 * @returns {Object} return.invalidFields - An array of invalid field IDs.
 * @returns {Object} return.fieldErrors - A structured object of field errors.
 */
function getFieldErrors({
	incomingValues,
	schema,
	debug,
}) {
	const { error } = schema.validate(incomingValues, { abortEarly: false, allowUnknown: true });

	if (!error) {
		return {
			invalidFields: [],
			fieldErrors: {},
		};
	}

	if (debug) {
		logInfo('Incoming data validation error:', { error });
	}

	const errorsObject = {};

	error.details?.forEach(({ context = {}, message }) => {
		errorsObject[context.key] = message;
	});

	return {
		invalidFields: Object.keys(errorsObject),
		fieldErrors: unFlatten(errorsObject),
	};
}

export default getFieldErrors;
