import equal from 'fast-deep-equal/react';
import { runMingo, unFlatten } from '@buddy-technology/ion-helpers';
import { hasCalculate, isRequired } from '../utils';
import { useDataContext } from '../context';

/**
  @typedef SetDynamicValuesParams
 * @property  {Function} getValues - getValues function from react-hook-form
 * @property  {Function} getFieldState - getFieldState function from react-hook-form
 * @property  {Function} setValue - setValue function from react-hook-form
 * @property  {Array} flatApplication - flat array of field objects that take a value.
*/

/**
 * @name useSetDynamicValues
 * @description Returns a function to calculates any dynamic fields that haven't been directly manipulated by the user.
 * @param  {SetDynamicValuesParams} SetDynamicValuesParams all params are required.
 * @returns {Function} - setDynamicValues function
 */
const useSetDynamicValues = ({
	getValues = isRequired('getValues'),
	getFieldState = isRequired('getFieldState'),
	setValue = isRequired('setValue'),
	flatApplication = isRequired('flatApplication'),
}) => {
	const { ion: { variables }, offeringOptions } = useDataContext();
	const setDynamicValues = (event = { target: {} }) => {
		const formValues = getValues();
		const fieldsToCalculate = flatApplication.filter(({ value }) => hasCalculate(value));
		const valuesToUpdate = fieldsToCalculate.reduce((prev, current) => {
			const { id, value } = current;
			const currentStateValue = formValues[id];
			const isTarget = event?.target?.name === id;
			const { isTouched } = getFieldState(id);
			// If a user has updated this field, don't update it.
			const isFocused = document.hasFocus() && document.activeElement?.name === id;
			if (isFocused || isTouched || isTarget) {
				return prev;
			}
			const { value: result } = runMingo(value._calculate, { ...unFlatten(formValues), variables, offeringOptions });
			// using fast-deep-equal to quickly compare objects, arrays, and primitives.
			if (!equal(currentStateValue, result)) {
				return { ...prev, [id]: result };
			}
			return prev;
		}, {});

		const valuesToUpdateEntries = Object.entries(valuesToUpdate);
		if (valuesToUpdateEntries.length) {
			valuesToUpdateEntries.forEach(([key, value]) => setValue(key, value));
		}
	};
	return setDynamicValues;
};

export default useSetDynamicValues;
