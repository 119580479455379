import calculateIsHidden from './calculateIsHidden';

/**
 * @param  {Object[]} viewsOrFields - views or fields from ION
 * @param  {Object} rawState - top level form state
 * @returns {Object[]} - array with only views/fields that should be shown. Also removes quote and checkout views by default.
 */
const filterConditionalFieldsOrViews = (viewsOrFields, rawState, variables, offeringOptions) => {
	const filtered = viewsOrFields.filter(({
		id, isHidden, uiDisplay,
	}) => {
		const isHiddenProp = uiDisplay?.isHidden ? uiDisplay.isHidden : isHidden;
		return !calculateIsHidden(isHiddenProp, { variables, rawState, offeringOptions }); // if isHidden is truthy, then return false to hide it.
	});
	return filtered;
};

export default filterConditionalFieldsOrViews;
