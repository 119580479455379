import React, { forwardRef, useEffect } from 'react';
import { useEventContext, useDataContext } from '../../context';
import { replaceSpecialChars } from '../../utils';
import Markdown from './Markdown';
import generateDynamicOptions from '../../utils/generateDynamicOptions';
/**
 * @typedef  {Object} RadioInputTabsProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input
 * @property {Array<{ label: string, value: string }>} optionLabels - An array of input options
 * @property {Array<{ label: string, value: string }>} dynamicOptionLabels - An array of input options that are dynamically generated via _calculate in the ION
 * @property {Array<{ text: string, id: string }>} tabPanels - An array of panels associated with the radio options rendered as "tabs"
 * @property {Function} register - RHF method for initializing a field
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {Number} viewId - the id of the parent view; used to dynamically generate id
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * Pass through html params (aria-*, onClick, etc) in addition to the params below.
 * @description A single choice selector built around the HTML Input type radio element.
 * @param {RadioInputTabsProps} props
 * @param  {Object} [ref]
 */
const RadioInputTabs = ({
	id,
	htmlId,
	label = null,
	optionLabels = [],
	dynamicOptionLabels,
	register,
	errors,
	getValues,
	trigger,
	isEndorsable,
	isRequired,
	isDisabled,
	helpText,
	viewId,
	control,
	tabPanels,
	...rest
}, ref) => {
	const currentSelectedValue = getValues(id);

	const { eventCallback } = useEventContext();
	const { ion: { variables }, offeringOptions } = useDataContext();

	const dynamicOptionsToRender = generateDynamicOptions({
		dynamicOptionLabels, getValues, variables, control, offeringOptions,
	}) || [];

	const optionsToRender = dynamicOptionsToRender.length ? dynamicOptionsToRender : optionLabels;

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<>
			<div>
				<fieldset>
					<legend className="sr-only">{label}</legend>
					<div className="tablist" role="tablist" aria-label="Sample Tabs">
						{optionsToRender.map((option, index) => {
							const inputId = `${replaceSpecialChars(option.value)}`;
							return (
								<React.Fragment key={inputId}>
									<input
										id={inputId}
										role="tab"
										name="tabset"
										checked={currentSelectedValue === inputId}
										className="radio-button form-radio disabled:cursor-not-allowed tab"
										data-testid="radio"
										aria-controls={`panel${index + 1}`}
										aria-selected={currentSelectedValue === inputId ? 'true' : 'false'}
										type="radio"
										disabled={isDisabled}
										ref={ref}
										value={inputId}
										// Safari doesn't focus radio buttons, but to ensure we don't overwrite user manipulated values, we need the buttons to focus.
										onClick={(e) => e.target.focus()}
										{...register(id, {
											onChange: (e) => {
												trigger(id);
												eventCallback('onRadioSelection', {
													timestamp: Date.now(), elementId: label, value: e.target.value,
												});
											},
										})}
										tabIndex={currentSelectedValue === inputId ? 0 : -1}
										{...rest}
									/>
									<label
										id={`${inputId}-label`}
										htmlFor={inputId}
										className={`radio-button-label ${errors ? 'input-invalid' : ''}${currentSelectedValue === inputId ? 'active' : ''}`}
										aria-labelledby={inputId}
									>
										{option.label}
									</label>
								</React.Fragment>
							);
						})}
					</div>
					<div className="tabpanels">
						{(tabPanels ?? []).slice(0, optionsToRender.length).map((tab, index) => (
							<div
								key={tab.id}
								role="tabpanel"
								id={`panel${index + 1}`}
								data-id={tab.id}
								aria-labelledby={`${replaceSpecialChars(optionsToRender[index].value)}`}
								aria-hidden={currentSelectedValue === `${replaceSpecialChars(optionsToRender[index].value)}` ? 'false' : 'true'}
								className={currentSelectedValue === `${replaceSpecialChars(optionsToRender[index].value)}` ? 'tabpanel active' : 'tabpanel'}
								style={{
									visibility: currentSelectedValue === `${replaceSpecialChars(optionsToRender[index].value)}` ? 'visible' : 'hidden',
								}}
							>
								<Markdown
									getValues={getValues}
									variables={variables}
									key={`${tab.text}-${tab.id}`}
									text={tab.text}
									index={`panel${index}`}
									viewId={viewId}
									control={control}
								/>
							</div>
						))}
					</div>
				</fieldset>
			</div>

			{errors ? (
				<p className={`${errors ? 'input-error' : ''}`} data-testid="error-message">
					{errors.message}
				</p>
			) : null}

		</>
	);
};

export default forwardRef(RadioInputTabs);
