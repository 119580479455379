const ACTION_TYPES = {
	CALL: 'CALL',
	POST_MESSAGE: 'POST_MESSAGE',
};

const ACTION_SUB_TYPES = {
	FIELD_CHANGE: 'FIELD_CHANGE',
	LOAD: 'LOAD',
	RESOLVE: 'RESOLVE',
	TRIGGER: 'TRIGGER',
};

const BASE_VIEW_STATUSES = {
	OFFER_ONLY_QUOTE: 'offer-only-quote',
	OFFER: 'offer',
	QUOTE: 'quote',
	CHECKOUT: 'checkout',
	THANKS: 'thanks',
};

const CALL_STATUSES = {
	ABORTED: 'ABORTED',
	ERROR: 'ERROR',
	PENDING: 'PENDING',
	RESOLVED: 'RESOLVED',
};

const CONTENT_POSITION_TYPES = {
	BEFORE_FIELDS: 'BEFORE_FIELDS',
	AFTER_FIELDS: 'AFTER_FIELDS',
	BELOW_NAV: 'BELOW_NAV',
};

const INVALID_DISPLAY_TYPES = {
	OFFER_ONLY: 'OFFER_ONLY',
	PAGINATED: 'PAGINATED',
};

const NAV_ACTIONS = {
	BACK: 'BACK',
	DIRECT: 'DIRECT',
	NEXT: 'NEXT',
};

const PAYMENT_TYPES = {
	ACH: 'ACH',
	CUSTOMER_REFERENCE: 'CUSTOMER_REFERENCE',
	NO_COLLECT: 'NO_COLLECT',
	PAYMENT_METHOD_ID: 'PAYMENT_METHOD_ID',
	PAYMENT_TOKEN: 'PAYMENT_TOKEN',
};

const REACTION_TYPES = {
	NAVIGATE: 'NAVIGATE',
	ORDER: 'ORDER',
	POST_MESSAGE: 'POST_MESSAGE',
	SET: 'SET',
};

const RENDERED_VIEW_TYPES = {
	GROUP: 'GROUP',
	QUOTE: 'QUOTE',
	CHECKOUT: 'CHECKOUT',
	POST_CHECKOUT: 'POST_CHECKOUT',
};

const STAGES = {
	LOCAL: 'LOCAL',
	DEVELOPMENT: 'DEVELOPMENT',
	TESTING: 'TESTING',
	STAGING: 'STAGING',
	PRODUCTION: 'PRODUCTION',
};

const VIEW_TYPES = {
	PAGINATED: 'paginated',
	OFFER_ONLY: 'offer-only',
	SINGLE_FORM: 'single-form',
};

const ION_VIEW_TYPES = {
	CHECKOUT: 'CHECKOUT',
	GROUP: 'GROUP',
	OFFER_ONLY: 'OFFER_ONLY',
	POST_CHECKOUT: 'POST_CHECKOUT',
	QUOTE: 'QUOTE',
};

export {
	ACTION_SUB_TYPES,
	ACTION_TYPES,
	BASE_VIEW_STATUSES,
	CALL_STATUSES,
	CONTENT_POSITION_TYPES,
	ION_VIEW_TYPES,
	INVALID_DISPLAY_TYPES,
	NAV_ACTIONS,
	PAYMENT_TYPES,
	REACTION_TYPES,
	RENDERED_VIEW_TYPES,
	STAGES,
	VIEW_TYPES,
};
