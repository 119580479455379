import { useRef } from 'react';
import equal from 'fast-deep-equal/react';

/**
 * Custom hook that performs a deep comparison of the provided value and returns a memoized value.
 *
 * @param {any} value - The value to be deeply compared.
 * @returns {any} - The memoized value that only updates when the deep comparison detects a change.
 */
const useDeepCompare = (value) => {
	const ref = useRef();

	if (!ref.current || !equal(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
};

export default useDeepCompare;
