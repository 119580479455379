import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useDeepCompareEffect } from 'react-use';
import { diff } from 'just-diff';
import pick from 'lodash/pick';

import {
	createOrderPayload,
	ION_TO_JOI,
	getIonValues,
	getFields,
} from '@buddy-technology/ion-helpers';

import { Button } from './UI';
import { IF } from './helpers';
import AppEditorViews from './AppEditorViews';
import { useSetDynamicValues } from '../hooks';
import { isNumber, filterConditionalFieldsOrViews } from '../utils';

// helper functions for working with the returned diff object.
const isActualUpdate = (update) => !(update.op === 'add' && update.value === '');
const createPathFromDiff = (change) => (
	// filter out array keys, we only want key names.
	change.path.filter((pathPart) => !isNumber(pathPart))
		.join('.'));

/**
 * @typedef  {Object} ApplicationEditorProps
 * @property {Object} ion - The ION object that contains the application data.
 * @property {Object} [data={}] - The corresponding data for a specific policy that should be displayed.
 * @property {Object} [theme={baseTheme: 'base'}] - An object representing a stylized them. Base theme used as default.
 * @property {Function} [onEndorseSubmit=()=>{}] - Callback on submit.
 * @property {Function} [onEndorseSubmitError=()=>{}] -Callback for submit errors.
 * @property {Function} [eventCallback=()=>{}] - Callback for user events.
 * @property {String} [appEditorTitle="Update Coverage"] - Renders an h2 element at the top of the application editor.
 * @property {String} [appEditorSubtitle="The following policy details can be updated."] - Renders an h3 element underneath the title.
*/

/**
 * @description A component for rendering the ION Application data that's supplied
 * @param {ApplicationEditorProps} props
 */

const DEFAULTS = {
	DATA: {},
	ON_SUBMIT_ERROR: () => {},
	ON_SUBMIT: () => {},
	STAGE: 'STAGING',
	SUB_TITLE: 'The following policy details can be updated.',
	TITLE: 'Update Coverage',
};

const ApplicationEditorComponent = ({
	ion,
	data = DEFAULTS.DATA,
	onEndorseSubmitError = DEFAULTS.ON_SUBMIT_ERROR,
	onEndorseSubmit = DEFAULTS.ON_SUBMIT,
	submitLabel = 'Update Policy',
	stage = DEFAULTS.STAGE,
	appEditorSubtitle = DEFAULTS.SUB_TITLE,
	appEditorTitle = DEFAULTS.TITLE,
	placesApiKey,
}) => {
	const views = ion?.application?.views || [];

	const fields = getFields(ion);
	// Only render fields matched in the data object.
	const parentDataKeys = Object.keys(data);

	// omit premium total, since we show that as "original premium."
	const filteredApplication = fields.filter((field) => (parentDataKeys.includes(field.id.split('::')[0])));

	// Set starting values or defaults for fields that have no corresponding data (to initialize the form).
	const formState = getIonValues(filteredApplication, data);

	// This is tricky. We want to validate our whole payload based on the new information, but we need to omit things like startDate, since in this case they're almost always in the past, but the ION rules state they must be in the future.
	const schemaFields = filteredApplication.filter((field) => field.id !== 'policy::startDate' && !field.id.includes('::utility::'));

	// Only send Joi fields that are endorsable.
	const schema = ION_TO_JOI(schemaFields);
	const {
		register: rhfRegister,
		control,
		handleSubmit,
		getValues,
		setValue: rhfSetValue,
		watch,
		reset,
		trigger,
		getFieldState,
		formState: {
			errors,
		},
	} = useForm({
		mode: 'onTouched',
		defaultValues: { ...formState },
		resolver: joiResolver(
			schema,
			{
				allowUnknown: true,
				abortEarly: false,
				errors: {
					wrap: {
						label: '',
						array: '"',
					},
				},
			},
		),
	});

	const viewsWithFields = views.filter((view) => view.content?.find((contentPiece) => contentPiece?.fields?.some(({ id: fieldId }) => !fieldId.includes('::utility'))));

	const _displayedViews = filterConditionalFieldsOrViews(viewsWithFields, getValues(), ion.variables);

	const [displayedViews, setDisplayedViews] = useState(_displayedViews);

	const updateViews = () => {
		const formValues = getValues();
		const newViews = filterConditionalFieldsOrViews(viewsWithFields, formValues, ion.variables);
		const serializeArr = (arr) => arr.map(({ id }) => id).join(',');
		const serializedViews = serializeArr(displayedViews);
		const serializedNew = serializeArr(newViews);
		if (serializedViews !== serializedNew) {
			setDisplayedViews(newViews);
		}
	};

	// we need to update incoming values to the RHF state if parent component updates.
	useDeepCompareEffect(() => {
		reset({ ...formState });
	}, [formState]);

	const handleSuccess = (formData) => {
		const formPayload = createOrderPayload(formData);

		// only return the fields that have been updated (different from original data object)
		const updates = diff(data, formPayload);

		const updatedPaths = updates.reduce((acc, current) => {
			const isUtility = current.path.some((pathPart) => pathPart.includes('utility'));

			// don't include utility fields or empty strings that were added as defaults.
			if (!isActualUpdate(current) || isUtility) {
				return acc;
			}
			acc.push(createPathFromDiff(current));
			return acc;
		}, []);

		// send back a payload with only the updated fields.
		const updatedPayload = pick(formPayload, updatedPaths);
		return onEndorseSubmit(updatedPayload);
	};

	const handleError = (error) => {
		// React Hook Form can sometimes returns a ref to the offending input, which will cause an error if we try to send it to the parent. So we clean it up here.
		const cleanedError = Object.entries(error).reduce((acc, [rawKey, value]) => {
			const key = rawKey.replace(/::/g, '.');
			acc[key] = { message: value.message, type: value.type };
			return acc;
		}, {});
		onEndorseSubmitError(cleanedError);
	};

	const setDynamicValues = useSetDynamicValues({
		getValues,
		getFieldState,
		setValue: rhfSetValue,
		variables: ion?.variables,
		flatApplication: filteredApplication,
	});

	const updateAppState = (e) => {
		updateViews();
		setDynamicValues(e);
	};

	const setValue = (key, value) => {
		rhfSetValue(key, value);
		/*
			No need to pass an event object to updateAppState in this instance since it's not an HTML event.
			SetValue is used imperatively in this instance, so no need to pass an event object (not that there is one available anyway).
			The event object is sometimes used to not override an input before its isTouched state has updated.
		*/
		updateAppState();
	};

	const register = (name, options = {}) => (
		rhfRegister(name, {
			...options,
			onChange: (e) => {
				if (options.onChange) {
					options.onChange(e);
				}
				const { type } = e?.target || {};
				if (['radio', 'checkbox'].includes(type)) {
					updateAppState(e);
				}
			},
			onBlur: (e) => {
				if (options.onBlur) {
					options.onBlur(e);
				}
				updateAppState(e);
			},
		})
	);

	const baseViewProps = {
		register,
		control,
		Controller,
		errors,
		setValue,
		trigger,
		watch,
		getValues,
		getFieldState,
		variables: ion?.variables || {},
		stage,
		updateAppState,
		placesApiKey,
	};

	const containerId = ion.id.replace(/_/g, '-').toLowerCase();

	return (
		<div id={containerId}>
			<div id="app-editor-container">
				<IF condition={appEditorTitle}>
					<h2 id="app-editor-title">
						{appEditorTitle}
					</h2>
				</IF>
				<IF condition={appEditorSubtitle}>
					<h3 id="app-editor-subtitle">
						{appEditorSubtitle}
					</h3>
				</IF>
				<AppEditorViews
					baseViewProps={baseViewProps}
					displayedViews={displayedViews}
					errors={errors}
				/>
				<div id="app-editor-update-policy-button">
					<Button
						className="mt-4"
						onClick={handleSubmit(
							(formData) => handleSuccess(formData),
							(error) => handleError(error),
						)}
						label={submitLabel}
					/>
				</div>
			</div>
		</div>
	);
};

export default ApplicationEditorComponent;
