// React Day Picker is the smallest date library I could find.
// their DateInput doesn't have masking, and it doesn't properly forward refs
// to a replacement component. This is why we're making our own hybrid component here
// using react-text-mask and react-date-picker.
import React, { useRef, useState, useEffect } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { IMaskInput } from 'react-imask';
import { parseDate, formatToStandardDate } from '@buddy-technology/buddy_helpers';
import IF from '../helpers/IF';
import { useEventContext } from '../../context/EventContext';

const createDisableModifier = (rules) => {
	// helper function to figure out the date the person must be born by to disable days before or after
	const getCalculatedAgeLimit = (rule) => {
		const date = new Date();
		const calculatedAgeLimit = new Date(date.setFullYear(date.getFullYear() - rule));
		return calculatedAgeLimit;
	};

	// creates the disable modifier based on the rules object
	const disableModifier = {};

	const min = rules.min || rules.greaterThan;
	let minOrGreaterThan = min;

	// this rule can be a string, or an object with properties: value, error
	if (minOrGreaterThan && typeof minOrGreaterThan === 'object') {
		minOrGreaterThan = min.value;
	}

	if (minOrGreaterThan) {
		disableModifier.before = parseDate(minOrGreaterThan);
	}

	const maxOrLessThan = rules.max || rules.lessThan;
	if (maxOrLessThan) {
		disableModifier.after = parseDate(maxOrLessThan);
	}

	const { maxAge, minAge } = rules;

	if (maxAge) {
		disableModifier.before = getCalculatedAgeLimit(rules.maxAge);
	}

	if (minAge) {
		disableModifier.after = getCalculatedAgeLimit(rules.minAge);
	}

	return disableModifier;
};

/**
 * @typedef  {Object} DateInputProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input - the input label
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {Function} control - A RHF method to registered controlled components
 * @property {Function} Controller - A RHF wrapper for external components
 * @property {Function} getValues - A RHF method for retrieving form values
 * @property {Function} setValue - A RHF method for setting a form value
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {string} [placeholder="MM/DD/YYY"] - A placeholder date to show users the format we're looking for
 * @property {Object} disabledDays  - see https://react-day-picker.js.org/api/DayPicker#disabledDays for more info.
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * @description A Calendar Input combo component. Users can click dates on the cal, or type in the input.
 * @param {DateInputProps} props
 */
function DateInput({
	id,
	htmlId,
	label = null,
	isEndorsable,
	isRequired,
	disableCalendar,
	control,
	Controller,
	getValues,
	errors,
	placeholder = 'MM/DD/YYYY',
	rules,
	autocomplete,
	isDisabled,
	helpText,
	updateAppState,
	variables,
	viewId,
}) {
	const [showCalendar, setShowCalendar] = useState(false);
	const inputRef = useRef();
	const containerRef = useRef(null);

	const getDatePickerValue = () => {
		const rhfValue = getValues(id);
		// wait til a user is done typing out full date before trying to parse
		// this quashes warnings in console.
		return rhfValue?.length === 10 ? parseDate(rhfValue) : null;
	};

	const datePickerValue = getDatePickerValue();

	// creates the date modifier for the field
	const disableModifier = createDisableModifier(rules);

	const { eventCallback } = useEventContext();

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (

		<Controller
			name={id}
			control={control}
			render={({ field }) => {
				const {
					onBlur: validate,
				} = field;

				const handleChange = (value) => {
					field.onChange(value);
				};
				const handleInputBlur = (e) => {
					eventCallback('onBlur', { timestamp: Date.now(), elementId: label });
					if (!showCalendar) {
						// only fire updateAppState and validate in the onBlur if we're in input-only mode.
						// handleDateClick will take care of the below if folks are using the calendar.
						updateAppState(e);
						validate();
					}
				};

				const handleDateClick = (date, { disabled }) => {
					// disables the buttons that are disabled
					if (disabled) {
						return;
					}
					const formattedDate = formatToStandardDate(date);
					handleChange(formattedDate);
					setShowCalendar(false);
					validate();
					// no event to pass here, which is ok, because input no longer has focus.
					updateAppState();
				};

				// We need the calendar to stay open for date clicks, but close it if you click outside of the calendar div.
				const handleClickOutside = (event) => {
					const isInsideCalendar = containerRef?.current?.contains(event.target);
					// the actual input ref is contained within inputElement.
					const isInsideInput = inputRef?.current?.inputElement?.contains(event.target);
					const isOutside = !isInsideCalendar && !isInsideInput;
					if (isOutside && showCalendar) {
						setShowCalendar(false);
					}
				};

				const handleInputFocus = () => {
					eventCallback('onFocus', { timestamp: Date.now(), elementId: label });
					if (!disableCalendar) {
						setShowCalendar(true);
					}
				};

				const dateValue = typeof field.value === 'string' ? field.value : '';

				if (typeof field.value !== 'string' && process.env.NODE_ENV !== 'test') {
					// eslint-disable-next-line no-console
					console.warn('Date value should be a string');
				}

				useEffect(() => {
					document.addEventListener('mousedown', handleClickOutside, false);
					return () => document.removeEventListener('mousedown', handleClickOutside, false);
				});

				return (
					<>
						<IMaskInput
							id={htmlId}
							mask="MM/DD/YYYY" // "{+1}(000)000-0000"
							blocks={{
								MM: {
									mask: '00',
									from: '01',
									to: '12',
								},
								DD: {
									mask: '00',
									from: '01',
									to: '31',
								},
								YYYY: {
									mask: '0000',
									from: '1900',
									to: '2100',
								},
							}}
							data-testid="date"
							inputRef={field.ref}
							value={dateValue}
							name={field.name}
							className={`input-text disabled:cursor-not-allowed${errors ? ' input-invalid' : ''}`}
							aria-invalid={!!errors || false}
							aria-required={isRequired || false}
							onAccept={handleChange}
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							placeholder={placeholder}
							autoComplete={autocomplete}
							disabled={isDisabled}
						/>
						{errors ? (
							<p className="input-error" data-testid="error-message">
								{errors.message}
							</p>
						) : null}

						<IF condition={showCalendar}>
							<div className="inline-block" ref={containerRef}>
								<DayPicker
									onDayClick={handleDateClick}
									value={datePickerValue}
									initialMonth={datePickerValue}
									month={datePickerValue}
									// dropped the modifiers prop in place of disabledDays and selectedDays.
									disabledDays={disableModifier}
									selectedDays={datePickerValue}
									modifiersStyles={{
										selected: {
											width: 44,
										},
									}}
								/>
							</div>
						</IF>
					</>
				);
			}}
		/>

	);
}

export default DateInput;
