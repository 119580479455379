import isPlainObject from 'lodash/isPlainObject';
import hasFrom from './hasFrom';

/**
 * Helper for getting RHF path and value from structured object to use in setValue()
 * @param  {Object} obj
 * @returns {Array<object>} - array of objects with key and value to be used in setValue(key, value)
 */
const getSetArgs = (obj, _parentId) => {
	const keys = Object.keys(obj);

	// Not recursing if it's set to _from, we'll need that to ref a value later.
	const shouldRecurse = (value) => (
		isPlainObject(value) && Object.keys(value).length && !hasFrom(value)
	);

	const reducer = (prev, current) => {
		const value = obj[current];
		let key = current;
		if (_parentId) {
			key = `${_parentId}::${current}`;
		}
		if (shouldRecurse(value)) {
			const nestedObj = getSetArgs(value, key);
			return [...prev, ...nestedObj];
		}
		return [...prev, { key, value }];
	};
	return keys.reduce(reducer, []);
};

export default getSetArgs;
