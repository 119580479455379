import React from 'react';
import { Info } from './Icons';

import Markdown from './Markdown';

/**
 * @typedef {Object} LabelParams
 * @property  {string} labelFor - the id for the corresponding input
 * @property {string} helpText - Optional additional information for the field label
 */

function ToolTip({
	labelFor,
	helpText,
	getValues,
	variables,
	viewId,
	control,
}) {
	return (
		<div className="tooltip group" id={`tooltip-${labelFor}`} data-testid="tooltip-container">
			<Info className="helpText-icon" id={`helpText-icon-${labelFor}`} />
			<span data-testid="tooltip-text" className="tooltip-text invisible group-hover:visible" id={`helpText-for-${labelFor}`}>
				<Markdown
					getValues={getValues}
					variables={variables}
					text={helpText}
					index="1"
					position="LABEL"
					viewId={viewId}
					control={control}
				/>
			</span>
		</div>

	);
}

export default ToolTip;
