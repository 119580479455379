import React from 'react';
import {
	CardElement,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';
import { useMedia } from 'react-use';
import { buildStripeStyles } from '../../theme/utils';
import Label from './InputLabel';
import { useThemeContext } from '../../context/ThemeContext';

/**
 * @typedef {Object} StripeInputProps
 * @property {string} [label='Credit Card']
 * @property {string} [labelClassName = '']
 * @property {string} [inputClassName = '']
 */

/**
 * @param {StripeInputProps} props
 */
const StripeInput = ({
	label = 'Credit Card',
	labelClassName,
	inputClassName,
}) => {
	const theme = useThemeContext();

	const { inputStyles } = buildStripeStyles(theme);
	const isMobile = useMedia('(max-width: 660px)');

	const cardInputOptions = {
		style: {
			base: inputStyles,
			invalid: {
				color: '#9e2146',
			},
		},
	};

	return (
		<Label isRequired className={labelClassName}>
			{label}
			{isMobile ? (
				<div data-testid="stripe-mobile-container" className={`stripe-mobile ${inputClassName}`}>
					<div id="credit-card-number" className="stripe-number-el input-text">
						<CardNumberElement
							placeholder="stripe-mobile-container"
							options={cardInputOptions}
						/>
					</div>
					<div id="credit-card-expiry-cvc-container" className="stripe-expiry-cvc-el">
						<div id="credit-card-expiry" className="stripe-expiry-el input-text">
							<CardExpiryElement
								options={cardInputOptions}
							/>
						</div>
						<div id="credit-card-cvc" className="stripe-cvc-el input-text">
							<CardCvcElement
								options={cardInputOptions}
							/>
						</div>
					</div>
				</div>
			) : (
				<div data-testid="stripe-container" className={`input-text ${inputClassName}`}>
					<CardElement
						options={cardInputOptions}
					/>
				</div>
			)}

		</Label>
	);
};

export default StripeInput;
