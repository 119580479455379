import fetch from 'isomorphic-fetch';
import { STAGES } from './dictionary';

const PARTNER_API_URL = {
	[STAGES.PRODUCTION]: 'https://partners.buddyinsurance.com',
	[STAGES.STAGING]: 'https://staging.partners.buddyinsurance.com',
	[STAGES.TESTING]: 'https://partners.buddy-testing.insure',
	[STAGES.DEVELOPMENT]: 'http://localhost:3013',
	[STAGES.LOCAL]: 'http://localhost:3013',
};

/**
 * Use this helper for creating requests a bit faster.
 * @param {Object} request - the request object for the fetch
 * @param  {string} request.url - the url
 * @param  {} [request.method='GET'] - the request method: 'GET, PUT, POST, DELETE'. Defaults to 'GET.'
 * @param  {} [request.body=null] - the request body. Defaults to null.
 * @param  {} [request.signal=null] - an abort signal instance to pass to the fetch. Defaults to null.
 * @returns {Promise} - makes the fetch call and returns the Promise.
 */
function createFetch({
	url, method = 'GET', body = null, token = null, signal = null,
}) {
	const obj = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Buddy-Client-Timezone': Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || null,
		},
	};

	if (token) {
		obj.headers.Authorization = `Bearer ${token}`;
	}

	if (body) {
		obj.body = JSON.stringify(body);
	}

	if (signal) {
		obj.signal = signal;
	}

	return fetch(url, obj);
}

/**
 * Quick helper function to throw relevant error with required argument.
 * Default required values to this function when needed.
 * @param  {string} argument - the name of the required argument
 */
function isRequired(argument) {
	throw new Error(`The argument ${argument} is required`);
}

/**
 * @typedef OrderObject
 * @property {String} partnerId
 * @property {Object} customer
 * @property {Object} policy
 * @property {Object} payment
 * @property {Object} [session]
*/
/**
 * purchasePolicy - purchase/create a Buddy policy.
 * @param {OrderObject} order - the order object with customer, policy and payment props.
 * @param {String} [stage = 'PRODUCTION'] - stage dictates which endpoints to use.
 * @returns {Promise}
*/
export const purchasePolicy = async (
	{
		partnerId = isRequired('partnerId'),
		customer = isRequired('customer'),
		policy = isRequired('policy'),
		payment = isRequired('payment'),
		session,
	},
	stage = STAGES.STAGING,
) => {
	try {
		const version = 'v3';
		const apiUrl = PARTNER_API_URL[stage.toUpperCase()];
		const path = 'policy';

		let url = `${apiUrl}/${version}/${path}?partner=${partnerId}`;

		if (stage.toUpperCase() !== STAGES.PRODUCTION) {
			const canSkipCheckParam = 'canSkipChecks';
			url += `&${canSkipCheckParam}=true`;
		}

		const body = {
			application: {
				customer,
				policy,
				payment,
			},
			session,
		};

		const response = await createFetch({
			url,
			method: 'POST',
			body,
		});
		const json = await response.json();
		// check the response for an issue
		if (!json.ok) {
			const err = new Error(json.message || json.error);
			return Promise.reject(err);
		}
		return json.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * @typedef QuoteParams
 * @property {String} partnerId
 * @property {String} offering
*/
/**
 *
 * @param {QuoteParams} data - Required quote params
 * @param {String} [stage='STAGING'] - stage dictates which endpoints to use.
 * @returns {Promise}
 */

export const getQuote = async (
	{
		partnerId = isRequired('partnerId'),
		...fields
	},
	stage = 'STAGING',
) => {
	try {
		const version = 'v3';
		const apiUrl = PARTNER_API_URL[stage.toUpperCase()];
		const path = 'policy/quote';
		const url = `${apiUrl}/${version}/${path}?partner=${partnerId}`;

		const response = await createFetch({ url, method: 'POST', body: fields });
		const json = await response.json();
		// check the response for an issue
		if (!json.ok) {
			const err = new Error(json.message || json.error);
			return Promise.reject(err);
		}
		return json.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * @typedef ChangeParams
 * @property {String} policyId
 * @property {String} token
 * @property {Object} policy
 * @property {Object} [session]
*/
/**
 *
 * @param {ChangeParams} data - Required quote params
 * @param {String} [stage='production'] - stage dictates which endpoints to use.
 * @returns {Promise}
 */

export const makeCall = async (
	{
		ionId = isRequired('ion id'),
		partnerId = isRequired('partner id'),
		signal,
		body,
	},
	stage = 'STAGING',
) => {
	try {
		const version = 'v3';
		const apiUrl = PARTNER_API_URL[stage.toUpperCase()];
		const url = `${apiUrl}/${version}/ion/${ionId}/call?partner=${partnerId}`;
		const response = await createFetch({
			url, method: 'POST', body, signal,
		});
		const json = await response.json();
		if (!json.ok) {
			return Promise.reject(json.error || json);
		}
		return json;
	} catch (error) {
		return Promise.reject(error);
	}
};
