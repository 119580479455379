import { useState } from 'react';
import { useDataContext } from '../context';
import { convertFieldIDtoRHF, filterConditionalFieldsOrViews, getMiddleViews } from '../utils';
import { BASE_VIEW_STATUSES, VIEW_TYPES, NAV_ACTIONS } from '../models/dictionary';
/**
 * @typedef UseNavigateParams
 * @property  {function} clearErrors - clearErrors function from react-hook-form
 * @property  {function} getValues - getValues function from react-hook-form
 * @property  {array} flatApplication - array of field objects from ION
 * @property  {string} initialView - the view to start with
 * @property  {array} stepThroughViews - array of views to step through
 * @property  {function} trigger - trigger function from react-hook-form
 * @property  {function} updateLastNavAction - callback for storing nav actions
 * @property  {object} variables - variables object from ION
 * @property  {object} [viewRef] - a mutable ref for updating current view ids without repainting. Useful for single page views.
 * @property  {enum} viewType - enum of possible view types: ['paginated', 'offer-only', 'single-form']
 */

/**
 * @typedef UseNavigateReturnObject
 * @property  {function} navigate - function for navigation. Updates current view.
 * @property  {string} currentView - the current view
 */

/**
 * @function useNavigate
 * @description hook for navigating through an ITR app.
 * @param  {UseNavigateParams} UseNavigateParams all params are required.
 * @returns {UseNavigateReturnObject} - object with navigate function and currentView property.
 */
const useNavigate = ({
	clearErrors,
	flatApplication,
	getValues,
	initialView,
	stepThroughViews,
	trigger,
	updateLastNavAction,
	viewRef,
	viewType,
}) => {
	const [currentView, setCurrentView] = useState(initialView);
	const { ion: { variables }, offeringOptions } = useDataContext();

	/**
	 * For navigating within the app
	 * @param  {String} location -'next' takes you to next view, back takes you back one view, viewId will take you directly to that view.
	 */
	const navigate = (location) => {
	/*
			This is not DRY, however, because navigate can be passed down and called inside other utilities, the reference to stepOrder can sometimes be stale. Filtering conditional views on navigate call will have a negligible impact on performance, but guarantee that we are always current before navigating. We need to keep the stepOrder outside this function to preserve LongForm updates.
		*/
		const visibleViews = filterConditionalFieldsOrViews(stepThroughViews, getValues(), variables, offeringOptions);
		const filteredVisibleViewIds = getMiddleViews(visibleViews).map(({ id }) => id);
		// steps is our array of view ids to use for navigation.
		const endViews = viewType === VIEW_TYPES.OFFER_ONLY
			? [BASE_VIEW_STATUSES.OFFER_ONLY_QUOTE]
			: [BASE_VIEW_STATUSES.CHECKOUT, BASE_VIEW_STATUSES.THANKS];
		const steps = [
			...filteredVisibleViewIds,
			...endViews,
		];
		const currentViewId = viewType === VIEW_TYPES.PAGINATED ? currentView : viewRef.current;
		const currentIndex = steps.indexOf(currentViewId);
		const next = steps[currentIndex + 1];
		const previous = steps[currentIndex - 1];
		const currentViewObj = stepThroughViews.find((_view) => _view.id === currentViewId);
		const currentFieldIds = currentViewObj?.content?.reduce((running, current) => {
			if (current.fields) {
				const ids = current.fields.map(({ id }) => convertFieldIDtoRHF(id));
				return [...running, ...ids];
			}
			return running;
		}, []) || [];

		const scrollToView = (id) => {
			const view = document.getElementById(`${id}-view-container`);
			if (view) {
				view.scrollIntoView({ behavior: 'smooth' });
				// update the ref so parents can be notified. Purposely re-assigning param here.
				// eslint-disable-next-line no-param-reassign
				viewRef.current = id;
			}
		};

		const goToView = (id) => {
			// update the ref so parents can be notified. Purposely re-assigning param here.
			// eslint-disable-next-line no-param-reassign
			viewRef.current = id;
			setCurrentView(id);
		};

		const _navigate = [VIEW_TYPES.PAGINATED, VIEW_TYPES.OFFER_ONLY].includes(viewType) ? goToView : scrollToView;

		const stepBackward = () => {
			if (currentIndex > 0) {
				clearErrors(currentFieldIds || []);
				updateLastNavAction(NAV_ACTIONS.BACK);
				_navigate(previous);
			}
		};

		const stepForward = () => {
		// in case we need to validate against a field that's hidden in the view
			const allFieldsInCurrentView = flatApplication.filter(({ uiDisplay }) => uiDisplay.view === viewRef.current);
			const currentFieldIdsToCheck = currentFieldIds;

			if (allFieldsInCurrentView) {
				allFieldsInCurrentView.map(({ id }) => currentFieldIdsToCheck.push(id));
			}

			if (currentFieldIdsToCheck) {
				trigger(currentFieldIdsToCheck, { shouldFocus: true }).then((_result) => {
				// trigger returns true if no errors on field
					if (_result) {
						updateLastNavAction(NAV_ACTIONS.NEXT);
						_navigate(next);
					}
				});
			} else {
				updateLastNavAction(NAV_ACTIONS.NEXT);
				_navigate(next);
			}
		};

		const navigateDirectlyTo = (destination) => {
			updateLastNavAction(NAV_ACTIONS.DIRECT);
			goToView(destination);
		};

		switch (location) {
			case NAV_ACTIONS.NEXT:
				stepForward();
				break;
			case NAV_ACTIONS.BACK:
				stepBackward();
				break;
			default:
				navigateDirectlyTo(location);
				break;
		}
	};

	return {
		currentView,
		navigate,
	};
};

export default useNavigate;
