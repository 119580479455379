import get from 'lodash/get';
import getSetArgs from './getSetArgs';
import hasFrom from './hasFrom';
import extractFromData from './extractFromData';
import { REACTION_TYPES } from '../models/dictionary';

/**
 * @typedef IReactionHandlers
 * @property {function} setValue - setValue function for updating form state.
 * @property {function} navigate - function for navigating views
 * @property {function} onCheckout - onCheckout callback
 */

/**
 * @typedef ISubActionOptions
 * @property {string} location - location to set values to or navigate to.
 * @property {object} values - object with data to set
 */

/**
 * @typedef IReaction
 * @property {string} type - one of SET, NAVIGATE, or CHECKOUT.
 * @property {ISubActionOptions} options
 */

/**
 * @typedef IRunReactionParams
 * @property {IReaction} reaction - the reaction object
 * @property {object} data - data object from the action to parse through
 * @property {function} setValue - setValue function for updating form state.
 * @property {function} navigate - function for navigating views
 * @property {function} onCheckout - onCheckout callback
 * @property {string} [origin] - optional origin of a postmessage.
 * @property {object} [source] - optional source of a postmessage.
*/

/**
 * @typedef IHandleReactionParams
 * @property {object[]} [reactions=[]] - array of reaction objects
 * @property {object} data - data object from the action to parse through
 * @property {function} defaultReaction - default function if no reactions found
 * @property {IReactionHandlers} object
*/

/**
 * @function runReaction
 * @param {IRunReactionParams} runReactionParams
 */
const runReaction = ({
	reaction = {},
	data = {},
	origin,
	source,
	setValue,
	navigate,
	onCheckout,
}) => {
	const {
		type,
		options: {
			location,
			targetOrigin = '*',
			values,
		} = {},
	} = reaction;
	switch (type) {
		case REACTION_TYPES.SET: {
			const setArgs = getSetArgs(values);
			setArgs.forEach(({ key, value }) => {
				let valueToUse = value;
				if (hasFrom(value)) {
					valueToUse = get(data, value._from);
				}
				setValue(key, valueToUse);
			});
			break;
		}
		case REACTION_TYPES.NAVIGATE:
			navigate(location);
			break;
		case REACTION_TYPES.ORDER:
			onCheckout(data);
			break;
		case REACTION_TYPES.POST_MESSAGE: {
			const extractedData = extractFromData(values, { data });
			const target = targetOrigin === 'ORIGIN' ? origin : targetOrigin;
			const destination = location === 'SOURCE' ? source : window;
			destination.postMessage(extractedData, target);
			break;
		}
		default:
			// eslint-disable-next-line no-console
			console.error(`ERROR: UNKNOWN ACTION ${type} ATTEMPTED.`);
	}
};

/**
 * @function handleReactions
 * @param {IHandleReactionParams} handleReactionParams
 */
const handleReactions = ({
	reactions = [],
	defaultReaction,
	...rest
}) => {
	if (reactions?.length) {
		reactions.forEach((reaction) => runReaction({ reaction, ...rest }));
		return;
	}
	defaultReaction();
};

export default handleReactions;
