import groupBy from 'lodash/groupBy';

/**
 * @typedef QuoteRequirements
 * @property {Array<String>} requiredFields - Array of field paths denoting required fields for quoting.
 * @property {Array<String>} optionalFields - Array of field paths denoting optional fields for quoting.
 */
/**
 * @typedef Pricing
 * @property {Object} quote - Object containing quote information.
 */
/**
 * Pulls all of the relevant data needed for making quotes
 * @param {object} ion - An ION Instance
 * @returns {(QuoteRequirements|Pricing)} Returns either an object with requiredFields and optionalFields arrays, or the full pricing object from the ION (to support v1).
 */
export const getQuoteRequirements = (ion) => {
	const { quote = {} } = ion.insurance.pricing;
	const requiredFields = quote.requiredFields || quote.fields || [];
	const { optionalFields = [] } = quote;
	return { requiredFields, optionalFields };
};

/**
 * @param  {Object[]} fields - Array of field objects
 * @param  {Object[]} views - Array of view objects
 * @param {array} includeOnly - an array of field ID's that only should be included in views
 * @returns {Object[]} - Array of view objects with fields property of the views respective fields.
 */
export const getStepThroughViews = ({
	fields, views, includeOnly,
}) => {
	// clone and add undefined view to catch and fields that don't have views
	const viewInfo = [...views, { id: 'undefined', order: views.length + 1 }];
	const stripExcludedFields = (allFields, fieldsToKeep) => {
		const whitelist = allFields.filter((field) => fieldsToKeep.includes(field.id));
		return whitelist;
	};
	const fieldsToUse = includeOnly ? stripExcludedFields(fields, includeOnly) : fields;
	const viewedFields = groupBy(fieldsToUse, (field) => field?.uiDisplay?.view);
	const stepThroughViews = viewInfo
		.map((view) => ({
			...view, fields: viewedFields[view.id], case: view.id,
		}))
		// filter out any views without actual fields and that don't have an id to keep the array clean.
		.filter((view) => !(view.id === 'undefined' && !view.fields?.length))
		.sort((a, b) => a.order - b.order);

	return stepThroughViews;
};
