import React, { useEffect } from 'react';
import Creatable from 'react-select/creatable';
import { useThemeContext } from '../../context/ThemeContext';
import { buildCreatableStyles } from '../../theme/utils';
import { useEventContext } from '../../context/EventContext';

/**
 * @typedef  {Object} CreatableSelectProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input
 * @property {string} [placeholder] - A default text to show before entering or selecting an item
 * @property {Array<{ label: string, value: string }>} optionLabels - An array of input options
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isEditorMode] - A flag to determine if the element should render for ApplicationEditor
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {Function} control - A RHF method to registered controlled components
 * @property {Function} Controller - A RHF wrapper for external components
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * @param {CreatableSelectProps} props
 */
const CreatableSelect = ({
	id,
	htmlId,
	label = null,
	placeholder,
	optionLabels: options,
	control,
	Controller,
	errors,
	flatApplication,
	getFieldState,
	isEditorMode,
	isEndorsable,
	isRequired,
	isDisabled,
	helpText,
	getValues,
	setValue,
	updateAppState,
	variables,
	viewId,
	...rest
}) => {
	const theme = useThemeContext();
	const { eventCallback } = useEventContext();
	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);
	return (
		<>
			<Controller
				name={id}
				control={control}
				render={({ field }) => {
					const handleChange = (value) => {
						field.onChange(value);
						// Since this field is controlled, we need to manually trigger our dynamic value helper.
						updateAppState();
					};
					const handleBlur = (e) => {
						field.onBlur();
						// Since this field is controlled, we need to manually trigger our dynamic value helper.
						updateAppState(e);
						eventCallback('onBlur', { timestamp: Date.now(), elementId: label });
					};
					return (
						<Creatable
							id={htmlId}
							inputRef={field.ref}
							name={field.name}
							styles={buildCreatableStyles(theme)}
							defaultValue={options.find(({ value }) => value === field.value)}
							placeholder={placeholder || 'Select or Create an Option'}
							aria-errormessage={errors?.message}
							aria-invalid={!!errors}
							aria-label="Select or Create An Option"
							options={options}
							onChange={(val) => handleChange(val.value)}
							onBlur={handleBlur}
							onFocus={() => eventCallback('onFocus', { timestamp: Date.now(), elementId: label })}
							disabled={isDisabled}
						/>
					);
				}}
			/>

			{errors ? (
				<p className="input-error">
					{errors.message}
				</p>
			) : null}
		</>
	);
};

export default CreatableSelect;
