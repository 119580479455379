const protocols = ['http', 'https', 'mailto', 'tel'];

/**
 * @description URI transformer for ensuring link safety in Markdown conversions.
 * @param {string} uri
 * @returns {string}
 */

function transformUri(uri) {
	const url = (uri || '').trim();
	const first = url.charAt(0);

	if (first === '#' || first === '/') {
		return url;
	}

	const colonIndex = url.indexOf(':');
	if (colonIndex === -1) {
		return url;
	}

	if (uri.startsWith('data:application/pdf')) {
		return url;
	}

	let index = -1;

	// eslint-disable-next-line no-plusplus
	while (++index < protocols.length) {
		const protocol = protocols[index];

		if (
			colonIndex === protocol.length &&
      url.slice(0, protocol.length).toLowerCase() === protocol
		) {
			return url;
		}
	}

	index = url.indexOf('?');
	if (index !== -1 && colonIndex > index) {
		return url;
	}

	index = url.indexOf('#');
	if (index !== -1 && colonIndex > index) {
		return url;
	}

	// eslint-disable-next-line no-script-url
	return 'javascript:void(0)';
}

export default transformUri;
