import { ION_TO_JOI, runMingo, unFlatten } from '@buddy-technology/ion-helpers';
import hasCalculate from './hasCalculate';
import findViewByFieldId from './findViewByFieldId';

function getOfferOnlyViews({
	stepThroughViews,
	flatApplication,
	incomingValues,
	offeringOptions,
	variables,
}) {
	const viewsWithBadFields = new Set();
	const fieldsToValidate = flatApplication.filter(({ id }) => !id.startsWith('payment::'));
	const fieldsNeedingCalculate = fieldsToValidate.filter(({ value }) => hasCalculate(value));

	const valuesToUpdate = fieldsNeedingCalculate.reduce((prev, current) => {
		const { id, value } = current;
		const { value: result } = runMingo(value._calculate, { ...unFlatten(incomingValues), offeringOptions, variables });
		return { ...prev, [id]: result };
	}, {});

	const payload = Object.assign(incomingValues, valuesToUpdate);
	const schema = ION_TO_JOI(fieldsToValidate);

	const { error } = schema.validate(payload, { abortEarly: false, allowUnknown: true });
	if (error) {
		error?.details.forEach(({ path }) => {
			const [key] = path;
			const correspondingView = findViewByFieldId(key, stepThroughViews);
			if (correspondingView) {
				viewsWithBadFields.add(correspondingView);
				return;
			}
			// the below is for legacy ions that utilize uiDisplay.view instead of the fields in the views content array.
			const fieldObj = flatApplication.find(({ id }) => id === key);
			const { view: viewId } = fieldObj?.uiDisplay || {};
			if (viewId) {
				viewsWithBadFields.add(viewId);
			}
		});
	}

	const offerOnlyViews = stepThroughViews.filter(({ id }) => viewsWithBadFields.has(id));
	return offerOnlyViews;
}

export default getOfferOnlyViews;
