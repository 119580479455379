import React, { forwardRef, useEffect } from 'react';
import { useEventContext, useDataContext } from '../../context';
import generateDynamicOptions from '../../utils/generateDynamicOptions';
/**
 * @typedef  {Object} CheckboxInputProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input
 * @property {Array<{ label: string, value: boolean }>} optionLabels - An array of input options
 * @property {Function} register - RHF method for initializing a field
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled
 */

/**
 * Pass through html params (aria-*, onClick, etc) in addition to the params below.
 * @description A single or multiple select element built from the HTML Input type checkbox element
 * @param {CheckboxInputProps} props
 * @param {Object} [ref]
 */
const CheckboxInput = ({
	id,
	htmlId,
	label = null,
	optionLabels = [],
	dynamicOptionLabels,
	register,
	errors,
	isEndorsable,
	isRequired,
	isDisabled,
	helpText,
	getValues,
	trigger,
	viewId,
	defaultValue,
	type,
	control,
	...rest
}, ref) => {
	const { eventCallback } = useEventContext();
	const { ion: { variables }, offeringOptions } = useDataContext();

	const dynamicOptionsToRender = generateDynamicOptions({
		dynamicOptionLabels, getValues, variables, control, offeringOptions,
	});

	const optionsToRender = dynamicOptionsToRender.length ? dynamicOptionsToRender : optionLabels;

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<>
			<div>
				<fieldset>
					<legend className="sr-only">{label}</legend>
					{optionsToRender.map((option) => (
						<div id={`${htmlId}-checkbox-wrapper`} key={option.key || option.value} className="checkbox-wrapper">
							<input
								id={`${htmlId}-${option.value.toString()}`}
								name={id}
								className="input form-checkbox disabled:cursor-not-allowed"
								type="checkbox"
								data-testid="checkbox"
								disabled={isDisabled}
								ref={ref}
								value={option.value}
								// Safari doesn't focus checkboxes, but to ensure we don't overwrite user manipulated values, we need the checkbox to focus.
								onClick={(e) => e.target.focus()}
								{...register(id, {
									onChange: (e) => {
										trigger(id);
										eventCallback('onCheckboxSelection', {
											timestamp: Date.now(), elementId: label, checked: e.target.checked, value: option.label,
										});
									},
								})}
								{...rest}
							/>
							<label
								htmlFor={`${htmlId}-${option.value.toString()}`}
								className={`ml-3 block ${errors ? 'input-invalid' : ''}`}
							>
								{option.label}
							</label>
						</div>
					))}
				</fieldset>
			</div>

			{errors ? (
				<p className={`${errors ? 'input-error' : ''}`}>
					{errors.message}
				</p>
			) : null}

		</>
	);
};

export default forwardRef(CheckboxInput);
