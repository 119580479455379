import React from 'react';

const TableAsCards = ({ children, contentId }) => {
	// get the thead and tbody from our markdown table
	const thead = children.find((child) => child.type === 'thead');
	const tbody = children.find((child) => child.type === 'tbody');

	const headers = thead.props.children[0].props.children.map((th) => th.props.children);
	const rows = tbody.props.children.map((tr, idx) => (
		<div className="table-card" id={`table-card-${idx}`} key={`table-card-${tr.key}`}>
			{
				tr.props.children.map((td, index) => (
					<div className="table-card-row" id={`table-card-row-${index}`} key={`table-card-row-${td.key}`}>
						<span className="header">
							{headers[index]}
						</span>
						<span className="value">{td.props.children}</span>
					</div>
				))
			}
		</div>
	));

	return <div className="table-card-container" id={contentId ? `table-card-container-${contentId}` : 'table-card-container'}>{rows}</div>;
};

const MarkdownTableToCards = ({ children, contentId }) => (
	<>
		<div className="table-container" id={`${contentId}-table-container`} data-testid="table-container">
			<table>
				{children}
			</table>
		</div>
		<div className="card-container" id={`${contentId}-card-container`} data-testid="card-container">
			<TableAsCards contentId={contentId}>
				{children}
			</TableAsCards>
		</div>
	</>
);

export default MarkdownTableToCards;
