import React, { forwardRef, useEffect } from 'react';
import { useEventContext } from '../../context/EventContext';

/**
 * @typedef  {Object} InputProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input
 * @property {Function} register - RHF method for initializing a field
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {string} [autocomplete] - An HTML attribute for setting a value autocompletion
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * Pass through html params (aria-*, onClick, etc) in addition to the params below.
 * @description A wrapper for the HTML Input element
 * @param {InputProps} props
 * @param {Object} [ref]
 */
const Input = ({
	id,
	htmlId,
	label = null,
	register,
	errors,
	isEndorsable,
	isRequired,
	placeholder,
	autocomplete,
	isDisabled,
	helpText,
	getValues,
	variables,
	viewId,
	control,
	...rest
}, ref) => {
	const { eventCallback } = useEventContext();
	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<>
			<input
				id={htmlId}
				className={`input-text${errors ? ' input-invalid' : ''} disabled:cursor-not-allowed`}
				data-testid="input"
				type="text"
				aria-invalid={!!errors}
				aria-required={isRequired}
				placeholder={placeholder}
				autoComplete={autocomplete}
				onFocus={() => eventCallback('onFocus', { timestamp: Date.now(), elementId: label })}
				ref={ref}
				disabled={isDisabled}
				{...register(id, { onBlur: (e) => eventCallback('onBlur', { timestamp: Date.now(), elementId: label }) })}
				{...rest}
			/>

			{errors ? (
				<p className="input-error" data-testid="error-message">
					{errors.message}
				</p>
			) : null}
		</>
	);
};

export default forwardRef(Input);
