import React from 'react';
import has from 'lodash/has';
import {
	replaceSpecialChars,
} from '../../../utils';
import IF from '../IF';
import {
	Pencil, XCircle, ZoomIn,
} from '../../UI/Icons';
import Markdown from '../../UI/Markdown';

const ItemPill = ({
	item,
	onEdit,
	onRemove,
	isEndorsable,
	isEditorMode,
	arrayItemTitle,
	getValues,
	variables,
	control,
	viewId,
	index,
}) => {
	let title = '';

	// Can now define this in the ION itself so there's no ambiguity.
	if (arrayItemTitle !== null) {
		const regex = /\[i\]/g;
		const replaceIndexPosition = arrayItemTitle.replace(regex, `[${index}]`);
		title = replaceIndexPosition;
	} else if (has(item, 'name')) {
		title = item.name;
	} else {
		// If no name value, make the first string type be the title.
		const [string] = Object.values(item).filter((val) => typeof val === 'string');
		title = string || `Item ${index + 1}`;
	}

	return (
		<div id={`${replaceSpecialChars(title)}-array-item-pill-container`} className="array-item-pill-container">
			<button
				type="button"
				id={`${replaceSpecialChars(title)}-array-item-pill`}
				className="array-item-pill"
				data-testid="array-item-pill"
				onClick={onEdit}
			>
				{ isEditorMode
					? <ZoomIn className="item-pill-edit-button" />
					: <Pencil className="item-pill-edit-button" />}
				{arrayItemTitle !== null ? (
					<Markdown
						getValues={getValues}
						variables={variables}
						index="1"
						viewId={viewId}
						control={control}
						text={title}
					/>
				) : title}
			</button>
			<IF condition={!isEditorMode || (isEditorMode && isEndorsable)}>
				<button
					type="button"
					className="array-item-remove-button"
					data-testid="array-item-remove-button"
					onClick={onRemove}
				>
					<XCircle className="array-item-remove-icon" />
				</button>
			</IF>
		</div>
	);
};

export default ItemPill;
