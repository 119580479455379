import React from 'react';
import Field from './Field';

function AppEditorViews({
	baseViewProps,
	displayedViews,
	errors = {},
}) {
	const displayedFields = [];

	const getFieldsFromContent = (content) => {
		const fields = content.reduce((acc, current) => {
			const { fields: currentFields } = current;
			if (currentFields) {
			// filter out utility fields
				const filteredFields = currentFields.filter(({ id }) => !id.includes('::utility'));
				return [...acc, ...filteredFields];
			}
			return acc;
		}, []);
		return fields;
	};

	return (
		<div>
			{displayedViews.map((view) => {
				const { content, id: viewId } = view;
				const viewFields = getFieldsFromContent(content);
				return (
					<div key={viewId} className="app-editor-view-section" id={`${viewId}-section`}>
						{viewFields.map((field) => {
							const { id } = field;
							// don't render fields that are already displayed
							if (displayedFields.includes(id)) {
								return null;
							}
							// add this field to our list of displayed fields
							displayedFields.push(id);

							return (
								<Field
									key={id}
									{...baseViewProps}
									field={field}
									isEditorMode
								/>
							);
						})}
					</div>
				);
			})}
		</div>
	);
}

export default AppEditorViews;
