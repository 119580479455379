import isPlainObject from 'lodash/isPlainObject';
/**
 * Flattens objects to single level. This PRESERVES arrays!
 * @param  {Object} object - the original object
 * @param  {String} [separator = '.'] - optional separator param for path splitting. Defaults to "."
 * @param  {} _parentId - internal method for passing in parent key names when recursing.
 */
// eslint-disable-next-line default-param-last
const flattenObj = (object, separator = '.', _parentId) => {
	const keys = Object.keys(object);

	const reducer = (prev, current) => {
		const val = object[current];
		let key = current;
		if (_parentId) {
			key = `${_parentId}${separator}${current}`;
		}
		if (isPlainObject(val) && Object.keys(val).length) {
			const nestedObj = flattenObj(val, separator, key);
			return { ...prev, ...nestedObj };
		}
		return { ...prev, [key]: val };
	};
	return keys.reduce(reducer, {});
};

export default flattenObj;
