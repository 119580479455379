import React from 'react';

function Thanks({ orderID, email }) {
	return (
		<div className="mb-5">
			<h2 id="thank-you-header">Thank you for your purchase!</h2>
			<p className="text-lg">
        Your policy has been successfully created and a copy has been sent to
				{' '}
				<strong>{email || 'your email'}</strong>
				.
			</p>
			<p>
				{orderID && (
					<>
						<br />
          Your order id is
						{' '}
						<strong>{orderID}</strong>
						{' '}
            — please save for your records.
					</>
				)}
			</p>
		</div>
	);
}

export default Thanks;
