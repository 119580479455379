import React from 'react';
import ToolTip from './ToolTip';
import Markdown from './Markdown';

/**
 * @typedef {Object} LabelParams
 * @property  {string} labelFor - the id for the corresponding input
 * @property  {string} children - children
 * @property  {boolean} isInvalid - toggles invalid class
 * @property  {boolean} isRequired - toggles required asterisk
 * @property  {string} className - classNames to add
 * @property  {object} style    - match query, optional?
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * @description Label component for inputs
 * @param {LabelParams} props
 * @example
 * <Label isInvalid={errors} isRequired={isRequired}>
 *    <input />
 * </Label>
 */
function Label({
	labelFor,
	children,
	isInvalid,
	isRequired,
	className,
	style,
	helpText,
	getValues,
	variables,
	viewId,
	control,
}) {
	// checks for headers, links, images, code fences, block quote, bold, italic, strikethrough and dynomark mustache patterns. Ignores generic special characters by themselves to preserve text.
	const checkForDynomark = (el) => Boolean(el.match(/(^#)|(^>)|`|(\)\[)|(!\[)|({{)|(}})|(~~)|(==)|(\*{1,2}(?:(?!\*).)+\*{1,2})|(_{1,2}(?:(?!_).)+_{1,2})/g));

	const labelHasMarkOrDynoValues = typeof children === 'string' ? checkForDynomark(children) : null;

	return (
		<label
			htmlFor={labelFor}
			className={`input-label${className ? ` ${className}` : ''}${isInvalid ? ' input-invalid' : ''}`}
			style={style}
		>
			{isRequired ? <span className="text-negative required-asterisk" id={`${labelFor}-required-asterisk`}>* </span> : null}
			{labelHasMarkOrDynoValues ? (
				<Markdown
					getValues={getValues}
					variables={variables}
					text={children}
					index="1"
					position="LABEL-DYNOMARK"
					viewId={viewId}
					control={control}
				/>
			) : children}

			{typeof helpText !== 'undefined' ? (
				<ToolTip
					helpText={helpText}
					labelFor={labelFor}
					getValues={getValues}
					variables={variables}
					viewId={viewId}
					control={control}
				/>
			) : null}
		</label>

	);
}

export default Label;
