/**
 * @param  {string} base64 - base64 encoded string
 * @param  {string} [contentType=''] - content type (eg: 'application/pdf')
 * @param  {number} [sliceSize=512] - the byte character size for chunking this method (to improve performance). Best to leave this at 512.
 * @returns {Blob}
 */

function base64toBlob(base64, contentType = '', sliceSize = 512) {
	// atob decodes a Base64-encoded string into a new string with a character for each byte of the binary data.
	const byteCharacters = atob(base64);
	const byteArrays = [];

	// run this in slices to improve performance
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i += 1) {
			// create a new array of byte values
			byteNumbers[i] = slice.charCodeAt(i);
		}
		// convert array of byte values into a real typed byte array
		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}
	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

export default base64toBlob;
