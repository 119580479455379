import { createOrderPayload } from '@buddy-technology/ion-helpers';

/**
 * Creates a purchase payload from a flat application object.
 *
 * @param {Object} flatApplication - The flat application object containing customer, policy, payment, and session information.
 * @param {Object} flatApplication.customer - The customer information.
 * @param {Object} flatApplication.policy - The policy information.
 * @param {Object} [flatApplication.payment] - The payment information (optional).
 * @param {Object} [flatApplication.session] - The session information (optional).
 * @returns {Object} The purchase payload containing the application and session information.
 */
function createPurchasePayload(flatApplication) {
	if (!flatApplication) {
		return null;
	}

	const {
		customer,
		policy,
		payment,
		session = {},
	} = createOrderPayload(flatApplication);

	const payload = {
		application: {
			customer,
			policy,
		},
	};

	if (payment) {
		payload.application.payment = payment;
	}

	// only send allowed session data to API. (sometimes data is packed into session just for client use)
	payload.session = {
		channelUrl: payload.session?.channelUrl || window.location.href, // always pass channelUrl
		...(session.quoteId && { quoteId: session.quoteId }), // only pass quoteId if it exists
		...(session.lastUpdate && { quoteId: session.lastUpdate }), // only pass lastUpdate if it exists
	};

	return payload;
}

export default createPurchasePayload;
