/**
 * @function injectScript
 * @returns {HTMLElement}
 */
const injectScript = (SCRIPT) => {
	const script = document.createElement('script');
	script.src = `${SCRIPT.URL}`;
	document.head.appendChild(script);
	return script;
};

/**
* @function findScript
* @returns {(HTMLElement | undefined)}
*/
const findScript = (SCRIPT) => {
	const { URL, REGEX } = SCRIPT;
	const scripts = Array.from(document.querySelectorAll(`script[src^="${URL}"]`));
	const matchedScript = scripts.find(({ src }) => REGEX.test(src));
	return matchedScript;
};

let scriptPromise = null;

const loadScript = (SCRIPT) => {
	// Ensure that we only attempt to script once
	if (scriptPromise !== null) {
		return scriptPromise;
	}

	scriptPromise = new Promise((resolve, reject) => {
		if (typeof window === 'undefined') {
		// Resolve to null when imported server side. This makes the module
		// safe to import in an isomorphic code base.
			resolve(null);
			return;
		}

		try {
			let script = findScript(SCRIPT);
			if (script) {
			// eslint-disable-next-line no-console
				console.warn('script already loaded');
			} else if (!script) {
				script = injectScript(SCRIPT);
			}
		} catch (error) {
			reject(error);
		}
	});

	return scriptPromise;
};

export default loadScript;
