/**
 * Checks if all action fields are top-level objects.
 *
 * @param {Array<string>} fields - The fields to check (as dot-noted field ids).
 * @param {Array<string>} [topLevelKeys=['policy', 'customer']] - The top-level keys to check against.
 * @returns {boolean} - Returns true if all action fields are top-level objects, otherwise false.
 */
function isOnlyTopLevelFields(fields, topLevelKeys = ['policy', 'customer']) {
	return fields.every((fieldId) => (
		!fieldId.includes('.') && topLevelKeys.includes(fieldId)
	));
}

export default isOnlyTopLevelFields;
