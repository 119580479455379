import React, { createContext, useContext } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children, theme }) => (
	<ThemeContext.Provider value={theme}>
		{children}
	</ThemeContext.Provider>
);

export const useThemeContext = () => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error('useThemeContext must be used within a ThemeProvider');
	}
	return context;
};
