import React, { forwardRef } from 'react';

/**
 * @typedef  {Object} InputProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input
 * @property {Function} watch - RHF method for subscribing to values. Necessary for getting the ref value.
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 */

/**
 * Pass through html params (aria-*, onClick, etc) in addition to the params below.
 * @description A wrapper for the HTML Input element
 * @param {InputProps} props
 * @param {Object} [ref]
 */

const ReferenceInput = ({
	id,
	htmlId,
	label = null,
	register,
	errors,
	isRequired,
	placeholder,
	watch,
	...rest
}, ref) => (
	<>
		<input
			id={htmlId}
			className={`input-text${errors ? ' input-invalid' : ''} disabled:cursor-not-allowed`}
			data-testid="reference-input"
			type="text"
			aria-invalid={!!errors}
			disabled
			placeholder={placeholder}
			ref={ref}
			{...register(id)}
			{...rest}
		/>

		{errors ? (
			<p className="input-error" data-testid="error-message">
				{errors.message}
			</p>
		) : null}
	</>
);
export default forwardRef(ReferenceInput);
