import React, { forwardRef, useEffect } from 'react';
import { useEventContext, useDataContext } from '../../context';
import generateDynamicOptions from '../../utils/generateDynamicOptions';

/**
 * @typedef  {Object} SelectProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input - the input label
 * @property {Array<{ label: string, value: string }>} optionLabels - An array of input options
 * @property {Function} register - RHF method for initializing a field
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isEditorMode] - A flag to determine if the element should render for ApplicationEditor
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {string} [autocomplete] - An HTML attribute for setting a value autocompletion
 * @property {string} [defaultOption='Select an Option'] - The initial option on a Select that has no value -- usually a directive on what to do
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * @description An dropdown input based around the HTML Select Element
 * @param {SelectProps} props
 * @param {Object} [ref]
 */
const Select = ({
	id,
	htmlId,
	label = null,
	optionLabels = [],
	dynamicOptionLabels,
	register,
	errors,
	isEditorMode,
	isEndorsable,
	isRequired,
	autocomplete,
	defaultOption = 'Select an Option',
	isDisabled,
	helpText,
	getValues,
	viewId,
	control,
	...rest
}, ref) => {
	const { eventCallback } = useEventContext();
	const { ion: { variables }, offeringOptions } = useDataContext();

	let disableDynamic = false;

	const dynamicOptionsToRender = !optionLabels.length ? generateDynamicOptions({
		dynamicOptionLabels, getValues, variables, control, offeringOptions,
	}) : [];

	const optionsToRender = dynamicOptionsToRender.length ? dynamicOptionsToRender : optionLabels;

	if (!optionLabels.length && !dynamicOptionsToRender.length) {
		disableDynamic = true;
	}

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);
	return (
		<>
			<select
				id={htmlId}
				className={`input-select${errors ? ' input-invalid' : ''} disabled:cursor-not-allowed`}
				data-testid="select"
				disabled={isDisabled || disableDynamic}
				aria-invalid={!!errors || false}
				aria-required={isRequired || false}
				autoComplete={autocomplete}
				onFocus={() => eventCallback('onFocus', { timestamp: Date.now(), elementId: label })}
				ref={ref}
				defaultValue="" // react prefers this to using 'selected' on the option. this matches default option.
				{...register(id, {
					// this is weird it looks like we're using the onChange to create a blur, so this event will fire if it's an actual blur and if an element is selected
					onChange: (e) => { e.target.blur(); },
					onBlur: () => eventCallback('onBlur', { timestamp: Date.now(), elementId: label }),
				})}
				{...rest}
			>
				<option value="" disabled>
					{defaultOption}
				</option>
				{optionsToRender.map((option) => (
					<option key={`${htmlId}-${option.key || option?.value}`} value={option?.value}>
						{option?.label}
					</option>
				))}
			</select>

			{errors ? (
				<p className="input-error" data-testid="error-message">
					{errors.message}
				</p>
			) : null}
		</>
	);
};

export default forwardRef(Select);
