import React, { forwardRef } from 'react';
import { Spinner } from './Icons';

/**
 * @typedef  {Object} ButtonProps
 * @property {string} [label=null] - An optional label for the field input
 * @property {string} className - classNames to add
 * @property {string} [variant = 'primary']
 * @property {string} [type = 'button']
 * @property {(string | node)} children - children
 * @property {boolean} [isLoading]
 * @property {boolean} [disabled]
 */

/**
 * @description A button component
 * @param {ButtonProps} props
 * @param {Object} [ref]
 */
const Button = ({
	label,
	children,
	variant = 'primary',
	type = 'button',
	className = '',
	isLoading,
	disabled,
	...rest
}, ref) => (
	<button
		className={`button-${variant} ${className} disabled:opacity-50 disabled:cursor-not-allowed`}
		// eslint-disable-next-line react/button-has-type
		type={type}
		ref={ref}
		disabled={isLoading || disabled}
		{...rest}
	>
		{isLoading
			? <Spinner />
			: (label || children)}
	</button>
);

export default forwardRef(Button);
