function Loading({ className }) {
	const svgStyle = {
		animation: 'spin 1s linear infinite',
		height: '1.5rem',
		width: '1.5rem',
	};
	const containerStyle = {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '1.25rem',
		marginBottom: '1.25rem',
	};
	return (
		<div style={containerStyle}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				style={svgStyle}
				stroke="currentColor"
				data-testid="loader"
			>
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} transform="scale(1, -1) translate(0, -24)" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
			</svg>
		</div>
	);
}

export default Loading;
