import {
	getIonValues,
	runMingo,
	unFlatten,
} from '@buddy-technology/ion-helpers';
import merge from 'lodash/merge';
import hasCalculate from './hasCalculate';
import logInfo from './logInfo';

/**
 * Computes initial ION values by running calculations on the provided flat application data and setting defaults for missing data.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.flatApplication - The flat application data.
 * @param {Object} [params.incomingData={}] - The incoming data to merge with the flat application data.
 * @param {Object} [params.context={}] - Additional context to use in calculations (variables, offeringOptions, etc.).
 * @param {Object} [params.debug=false] - Whether or not to log debug information.
 * @returns {Object} - An object containing the calculated ION values to initialize our state with.
 */
const getInitialIonValues = ({
	flatApplication,
	incomingData = {},
	context = {},
	debug = false,
}) => {
	const defaultValues = getIonValues(flatApplication, incomingData);
	const calculatedValues = flatApplication.reduce((acc, { id, value }) => {
		if (hasCalculate(value)) {
			const { value: result, error } = runMingo(value._calculate, {
				...unFlatten(defaultValues),
				...context,
			});

			if (debug && error) {
				logInfo(`Error calculating initial value for ${id}: ${error}`);
			}

			if (!error) {
				acc[id] = result;
			}
		}
		return acc;
	}, {});

	const defaultAndCalculatedValues = merge(defaultValues, calculatedValues);
	return defaultAndCalculatedValues;
};

export default getInitialIonValues;
