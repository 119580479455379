/**
 * Finds the first view that contains a specific field id.
 *
 * @param {string} fieldId - The ID of the field to search for.
 * @param {Array<Object>} views - The array of view objects to search within.
 * @returns {string|undefined} - The ID of the view containing the field, or undefined if not found.
 */
const findViewByFieldId = (fieldId = '', views = []) => {
	// content can have multiple fields arrays, so we need to potentially check all of them, but return as soon as we find one.
	const view = views.find(({ content }) => content.some(({ fields }) => fields?.some(({ id }) => id === fieldId)));
	return view?.id; // returns undefined if not found.
};

export default findViewByFieldId;
