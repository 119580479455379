import useAction from './useAction';

const initialState = {
	isLoading: false,
	onNext: [], // this needs to start as an array. We'll return a single function in the hook.
	disableBack: false,
	shouldSkip: false,
};

function useMultipleActions(actions = [], actionOptions = {}) {
	const actionsArray = Array.isArray(actions) ? actions : [actions];

	const actionResponses = actionsArray.reduce((acc, action) => {
		const response = useAction(action, actionOptions);

		return {
			isLoading: acc.isLoading || response.isLoading,
			onNext: [...acc.onNext, response.onNext].filter(Boolean),
			disableBack: acc.disableBack || response.disableBack,
			shouldSkip: acc.shouldSkip || response.shouldSkip,
		};
	}, initialState);

	const handleNext = actionResponses.onNext?.length
		? () => actionResponses.onNext.forEach((func) => func())
		: null;

	return {
		...actionResponses,
		onNext: handleNext,
	};
}

export default useMultipleActions;
