import React from 'react';
import { calculateIsHidden } from '../../utils';
import { useDataContext } from '../../context';

const ProgressBar = ({
	currentIndex,
	stepOrder,
	currentViewObj,
	views,
	getValues,
}) => {
	const { ion: { variables }, offeringOptions } = useDataContext();
	const hasMilestones = views.some((view) => view.milestone);
	let percentage;
	if (hasMilestones) {
		const shouldHide = !currentViewObj.milestone || calculateIsHidden(currentViewObj.milestone?.isHidden, {
			rawState: getValues(),
			variables,
			offeringOptions,
		});
		if (shouldHide) {
			return null;
		}
		const milestones = views.reduce((acc, current) => {
			const { milestone } = current;
			if (!milestone) {
				return acc;
			}
			// don't dupe milestones
			const hasCurrentMilestone = !!(acc.find((el) => el?.id === current.milestone.id));
			if (hasCurrentMilestone) {
				return acc;
			}
			return [...acc, milestone];
		}, []);
		const currentMilestoneIndex = milestones.findIndex(({ id }) => id === currentViewObj.milestone.id) + 1;
		const completedMilestones = currentMilestoneIndex < 0 ? 0 : currentMilestoneIndex;
		percentage = Math.floor((completedMilestones / milestones.length) * 100);
	} else {
		percentage = Math.floor((currentIndex / (stepOrder.length - 1)) * 100);
	}
	const fill = percentage > 5 ? percentage : 5;

	return (
		<div className="progress-bar-container">
			<div data-testid="progress-bar" className="progress-bar" style={{ width: `${fill}%` }} />
		</div>
	);
};

export default ProgressBar;
