import React from 'react';
import { useEventContext } from '../context/EventContext';

const buddySite = 'https://buddy.insure';

const PoweredBy = ({
	url = buddySite, src, alt, partner, screen, isHidden,
}) => {
	const { eventCallback } = useEventContext();
	// isHidden must be explicit.
	if (isHidden === true) {
		return null;
	}

	const href = url === buddySite
		? `${url}/?utm_source=${partner}&utm_medium=embed_widget&utm_campaign=${screen}`
		: url;
	return (
	// we disable the next line, because we want to track which sites bring them to buddy
	// eslint-disable-next-line react/jsx-no-target-blank
		<div id="powered-by-buddy-container" style={{ maxWidth: '25%', marginLeft: 'auto' }}>
			{url ? (
				<a
					tabIndex="-1"
					href={href}
					target="_blank"
					rel="noreferrer"
					onClick={() => eventCallback('onExternalLink', { timestamp: Date.now(), externalLinkUrl: url })}
				>
					<img src={src} alt={alt} style={{ width: '100%' }} />
				</a>
			)
				: (
					<img src={src} alt={alt} style={{ width: '100%' }} />
				)}

		</div>
	);
};

export default PoweredBy;
