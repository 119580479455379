import React, { useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import { useEventContext } from '../../context/EventContext';

/**
 * @typedef  {Object} MaskedInputProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input - the input label
 * @property {string} [placeholder] - A default text to show before entering or selecting an item
 * @property {Object} format - An object containing display format settings
 * @property {Function} control - A RHF method to registered controlled components
 * @property {Function} Controller - A RHF wrapper for external components
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {string} helpText - Optional additional information for the field label
 */

/**
 * @description An input component that formats the value, according to it's mask, as a user types.
 * @param {MaskedInputProps} props
 */
const MaskedInput = ({
	id,
	htmlId,
	label = null,
	placeholder,
	format,
	control,
	Controller,
	errors,
	flatApplication,
	getFieldState,
	isEndorsable,
	isRequired,
	autocomplete,
	isDisabled,
	helpText,
	getValues,
	setValue,
	updateAppState,
	variables,
	viewId,
	...rest
}) => {
	const { eventCallback } = useEventContext();

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<Controller
			name={id}
			control={control}
			render={({ field }) => {
				const handleChange = (value) => {
					field.onChange(value);
				};
				const handleBlur = (e) => {
					// Since this field is controlled, we need to manually trigger our dynamic value helper.
					updateAppState(e);
					eventCallback('onBlur', { timestamp: Date.now(), elementId: label });
				};
				return (
					<>
						<IMaskInput
							id={htmlId}
							mask={format.mask} // "{+1}(000)000-0000"
							data-testid="mask"
							inputRef={field.ref}
							value={field.value}
							name={field.name}
							onFocus={() => eventCallback('onFocus', { timestamp: Date.now(), elementId: label })}
							onBlur={handleBlur}
							className={`input-text${errors ? ' input-invalid' : ''} disabled:cursor-not-allowed`}
							type="text"
							aria-invalid={!!errors || false}
							aria-required={isRequired || false}
							unmask
							disabled={isDisabled}
							onAccept={handleChange}
							placeholder={placeholder}
							autoComplete={autocomplete}
							{...rest}
						/>

						{errors ? (
							<p className="input-error" data-testid="error-message">
								{errors.message}
							</p>
						) : null}
					</>
				);
			}}
		/>
	);
};

export default MaskedInput;
