import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useWatch } from 'react-hook-form';
import { ION_TO_JOI } from '@buddy-technology/ion-helpers';
import {
	replaceSpecialChars,
	convertFieldIDtoRHF,
} from '../../../utils';
import IF from '../IF';
import { Button } from '../../UI';
import { PlusCircle } from '../../UI/Icons';
import ItemPill from './ItemPill';
import EditView from './EditView';
import { getFieldsToWatch } from './util';

const RenderedArray = ({
	clearErrors,
	id,
	rules,
	// Group/Array level errors. Array items have their own scoped errors in EditView
	errors,
	setValue,
	getValues,
	toggleNav = () => {},
	isEndorsable = false,
	isEditorMode = false,
	uiDisplay,
	updateAppState,
	viewId,
	...fieldProps
}) => {
	const { variables, control } = fieldProps;
	const items = rules?.items?.value;
	const arrayItemTitle = uiDisplay?.arrayItemTitle || null;
	const arrayValue = getValues(id);
	const error = errors[id];

	const htmlId = replaceSpecialChars(id);
	const { label, isDisabled = false } = uiDisplay;

	let maxLengthOfArray = rules?.maxLength || undefined; // for disabling our add button and showing a warning about max length.

	if (Array.isArray(rules.conditions)) {
		// We only care about maxLength rules here. All others will be validated on user entry.
		const conditionalMaxLengthRules = rules.conditions.filter((condition) => condition.then?.maxLength);

		for (let index = 0; index < conditionalMaxLengthRules.length; index += 1) {
			const { condition, then } = conditionalMaxLengthRules[index];
			// type isn't a rule, but inside the conditional rule obj.
			const { type, ...actualRules } = condition.rules;
			const obj = { id: condition.key, type, rules: actualRules };
			const schema = ION_TO_JOI([obj]);
			const key = convertFieldIDtoRHF(condition.key);
			const val = getValues(key);
			const { error: conditionError } = schema.validate({ [key]: val });
			if (!conditionError) {
				maxLengthOfArray = then.maxLength;
				// break early if our condition is met.
				break;
			}
		}
	}

	const lengthOfArray = arrayValue?.length;
	const disabled = lengthOfArray >= maxLengthOfArray || isDisabled;

	if (!items) {
		// not throwing an error, but warn the dev.
		// eslint-disable-next-line no-console
		console.warn('Missing items property inside rules object for Field Array');
		return null;
	}

	const fieldsToWatch = getFieldsToWatch(items);

	const watchedValues = useWatch({ control, name: fieldsToWatch });

	const watchedFields = fieldsToWatch.reduce((acc, current, index) => ({
		...acc,
		[current]: watchedValues[index],
	}), {});

	const [isEditing, setIsEditing] = useState(false);
	// TODO: Should this be a ref?
	const [currentIndex, setIndex] = useState(0);
	const [arrayKey, setArrayKey] = useState(0);

	const removeItem = (index) => {
		const copy = [...arrayValue];
		copy.splice(index, 1);
		setArrayKey(arrayKey + 1);
		setValue(id, copy);
		updateAppState();
	};

	const updateArrayItem = (updatedItem, index) => {
		const copy = [...arrayValue];
		copy.splice(index, 1, updatedItem);
		setValue(id, copy);
		clearErrors(id);
		updateAppState();
	};

	const handleEditClick = (index) => {
		setIndex(index);
		toggleNav(false);
		setIsEditing(true);
	};

	const handleExit = () => {
		toggleNav(true);
		setIsEditing(false);
	};

	return (
		<div data-testid="array-items-container" className="array-items-container">
			<span className="array-items-span">{label}</span>
			<Transition
				show={!isEditing}
				enter="transition duration-300"
				enterFrom="-translate-x-48"
				enterTo="translate-x-0"
			>
				<div className="array-fields-container" key={arrayKey} id={`${htmlId}-array-container`}>
					{arrayValue.map((el, i) => (
						<ItemPill
							isEndorsable={isEndorsable}
							key={JSON.stringify(el)}
							item={el}
							onRemove={() => removeItem(i)}
							onEdit={() => handleEditClick(i)}
							isEditorMode={isEditorMode}
							arrayItemTitle={arrayItemTitle}
							getValues={getValues}
							variables={variables}
							control={control}
							viewId={viewId}
							index={i}
						/>
					))}
					{/* Always show if not in editor mode | Otherwise, only show in Editor mode if prop is endorsable */}
					<IF condition={!isEditorMode || (isEditorMode && isEndorsable)}>
						<Button
							type="button"
							onClick={() => handleEditClick(arrayValue.length)}
							className="array-items-add-button"
							disabled={disabled}
						>
							<PlusCircle className="array-items-add-icon" />
							Click to Add
						</Button>
					</IF>
					<IF condition={disabled}>
						<p className="input-label">
							You are limited to
							{' '}
							{maxLengthOfArray}
							{' '}
							{label}
						</p>
					</IF>
					<IF condition={error}>
						<p className="input-label input-error">{error?.message}</p>
					</IF>
				</div>
			</Transition>
			<Transition
				show={isEditing}
				enter="transition duration-300"
				enterFrom="translate-x-48"
				enterTo="translate-x-0"
			>
				<EditView
					parent={id}
					i={currentIndex}
					items={items}
					onSave={(value) => updateArrayItem(value, currentIndex)}
					groupValues={arrayValue[currentIndex]}
					watchedFields={watchedFields}
					stateValues={getValues()}
					exit={handleExit}
					isEndorsable={isEndorsable}
					isEditorMode={isEditorMode}
					fieldProps={{ ...fieldProps, updateAppState }}
				/>
			</Transition>
		</div>
	);
};

export default RenderedArray;
