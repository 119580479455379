import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { Numbers } from '@buddy-technology/buddy_helpers';
import { createOrderPayload, ION_TO_JOI } from '@buddy-technology/ion-helpers';
import { useDeepCompareEffect } from 'react-use';
import { getQuote } from '../models/api';
import { RenderedView } from './helpers';
import { useEventContext, useDataContext } from '../context';
import { convertFieldIDtoRHF, getPremiumByInterval } from '../utils';
import IF from './helpers/IF';
import { Spinner } from './UI/Icons';

const QuoteComponent = ({
	error, isLoading, price, label,
}) => (
	<>
		<IF condition={!error}>
			<div id="quote-price-label-container" data-testid="quote-price-label-container">
				<div id="quote-price-label-name">{label}</div>
				{isLoading ? <Spinner className="inline-block" /> : <div id="quote-price-label-amount">{price}</div>}
			</div>
		</IF>
		<IF condition={error}>
			<p className="input-error" data-testid="error-message">
				{error}
			</p>
		</IF>
	</>
);

function Quote({
	id,
	offerOnlyMode,
	quoteRequirements,
	originalPremium,
	offering,
	register,
	control,
	trigger,
	filteredApplication,
	Controller,
	getValues,
	watch,
	setValue,
	errors,
	title,
	subTitle,
	variables,
	partnerId,
	fields,
	endorsableFieldPaths,
	stage,
	token,
	policyId,
	...props
}) {
	const [quoteError, setQuoteError] = useState();
	const [isLoading, setIsLoading] = useState();
	const { eventCallback } = useEventContext();
	const { ion, sessionId } = useDataContext();

	// grab some values. Don't use watch here! It'll trigger unnecessary re-renders.
	// Quote will re-render when our quote values update, so premiumTotal will update alongside that render.
	const premium = getValues('policy::premiumTotal');
	// grab ALL values. NEVER use watch here! It'll trigger unnecessary re-renders. Even more here than the above.
	const allValues = getValues();

	const { requiredFields = [], optionalFields = [] } = quoteRequirements;
	const allQuoteFields = [...optionalFields, ...requiredFields];
	const rhfQuoteFields = allQuoteFields.map((fieldId) => convertFieldIDtoRHF(fieldId));

	// subscribe to quote fields. Without this, component won't know when to attempt a quote.
	const watchedFields = useWatch({ control, name: rhfQuoteFields });

	const handleQuoteError = (error) => {
		let msg = error?.message || error?.error || error;
		if (typeof msg !== 'string') {
			msg = 'An error occurred retrieving your quote. Please try again later.';
		}
		if (typeof window !== 'undefined' && window.Sentry) {
			window.Sentry.withScope((scope) => {
				scope.setTags({
					rejectionType: 'quote',
				});
				window.Sentry.captureException(error);
			});
		}
		// If someone is referencing this variable in dynomark, we need to let them know there's an error.
		setValue('policy::premiumTotal', 'ERROR');
		eventCallback('onQuote', { timestamp: Date.now(), error: msg });
		setQuoteError(msg);
	};

	const handleQuoteSuccess = (payload) => {
		const { pricing: quote } = payload;
		const premiumByInterval = getPremiumByInterval(quote, ion);
		setValue('policy::premiumTotal', quote);
		setValue('policy::premiumByInterval', premiumByInterval);
		eventCallback('onQuote', { timestamp: Date.now(), pricing: quote });
	};

	const getQuotePayload = (quoteFields, fullData) => {
		// convert any calculated values based on state props.
		const state = createOrderPayload(fullData);
		const quoteState = pick(state, quoteFields);

		// in editor mode, we get offering from the policy id, so don't inject offering there.
		if (quoteState?.policy) {
			quoteState.policy.offering = offering;
		}
		return quoteState;
	};

	const validateQuoteData = (data, required = []) => {
		// RHF state has '::' as its separator. Easiest to convert the required array.
		const requiredRHF = required.map((field) => field.replace(/\./g, '::'));

		// gets only required data for quoting.
		const quoteDataReducer = (acc, current) => {
			const [key, value] = current;
			if (requiredRHF.includes(key)) {
				return { ...acc, [key]: value };
			}
			return acc;
		};
		const quoteData = Object.entries(data).reduce(quoteDataReducer, {});

		// build our quote schema from app fields
		const neededFields = filteredApplication.filter(({ id: fieldId }) => requiredRHF.includes(fieldId));
		const schema = ION_TO_JOI(neededFields);

		const { error } = schema.validate(quoteData, {
			abortEarly: true,
			allowUnknown: true,
			convert: true,
			render: true,
			errors: { wrap: { label: '[]' } },
			context: { variables, TODAY: new Date().setHours(-24, 0, 0, 0) },
		});

		if (error) {
			// hiding these messages from tests, they're really annoying.
			if (process.env.NODE_ENV !== 'test') {
			// eslint-disable-next-line no-console
				console.log(`The following errors need to be resolved to get a quote: ${error.message}`);
			}
			return false;
		}
		return true;
	};

	const updatePrice = async (data) => {
		setIsLoading(true);
		// If someone is referencing this variable in dynomark, we need to let them know it's loading.
		setValue('policy::premiumTotal', 'Loading...');
		setQuoteError(false);
		const dataToSend = cloneDeep(data);

		if (!dataToSend.session) {
			dataToSend.session = {};
		}

		dataToSend.session.sessionId = sessionId;
		dataToSend.carrierId = ion.carrier;

		try {
			const res = await getQuote(dataToSend, stage);
			handleQuoteSuccess(res);
		} catch (error) {
			handleQuoteError(error);
		}
		setIsLoading(false);
	};

	const hasValidFields = validateQuoteData(getValues(), requiredFields);

	// Using this special hook because we need to listen for changes within the quoteData object
	// Passing in Object.values(quoteData) logs a react error if the array changes size.
	useDeepCompareEffect(() => {
		// only call the quote API if all necessary fields are valid.
		if (hasValidFields) {
			const quoteData = getQuotePayload(allQuoteFields, allValues);
			updatePrice({ partnerId, ...quoteData }, allQuoteFields);
		}
	}, [watchedFields]);

	const { toUSD } = Numbers;
	const viewTitle = offerOnlyMode ? null : (title || 'Quote');

	const formatPrice = (price) => (price ? toUSD(price) : '');

	return (
		<div id="quote-view-wrapper">
			<RenderedView
				key="Quote"
				id={id}
				title={viewTitle}
				subTitle={offerOnlyMode ? null : subTitle}
				register={register}
				control={control}
				trigger={trigger}
				Controller={Controller}
				errors={errors}
				setValue={setValue}
				getValues={getValues}
				variables={variables}
				isLoading={isLoading}
				// always hide fields in offer only mode. If missing, they'll show in a different view.
				fields={offerOnlyMode ? null : fields}
				watch={watch}
				stage={stage}
				extraContent={(
					<QuoteComponent
						error={quoteError}
						isLoading={isLoading}
						label="Your Price:"
						price={formatPrice(premium)}
					/>
				)}
				{...props}
			/>
		</div>
	);
}

export default Quote;
