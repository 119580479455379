/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Global, jsx, CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { buildStyles } from '../../theme/utils';

const styleCache = createCache({
	key: 'buddy',
	stylisPlugins: [
		prefixer,
	],
});

const getFontImports = (fonts = []) => fonts.map((src) => <Global styles={`@import url('${src}');`} key={src} />);

function GlobalStyles({ children, theme = {} }) {
	const { webFonts, styles } = buildStyles(theme);
	return (
		<CacheProvider value={styleCache}>
			{getFontImports(webFonts)}
			<Global styles={styles} />
			{children}
		</CacheProvider>
	);
}

export default GlobalStyles;
