import React, { forwardRef, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useEventContext } from '../../context/EventContext';

/**
 * @typedef  {Object} FormatObject
 * @property {string} type
 * @property {string} currencyType
 * @property {number} precision
 */

/**
 * @typedef  {Object} SliderProps
 * @property {string} id - The fields ID
 * @property {string} [label=null] - An optional label for the field input - the input label
 * @property {FormatObject} format - An object containing display format settings
 * @property {Function} register - RHF method for initializing a field
 * @property {Function} getValues - A RHF method for retrieving form values
 * @property {Object} [errors] - A variable validation error object including a message property
 * @property {string} min - A setting value for the lower boundary for the slider range
 * @property {string} max - A setting value for the upper boundary for the slider range
 * @property {string} [increment] - A setting value for each step to jump on the slider
 * @property {boolean} [isEndorsable] - A flag to determine if the field is endorsable or not
 * @property {boolean} [isRequired] - A flag to determine if the input is required
 * @property {boolean} [isDisabled] - A flag to determine if the field is disabled or not
 * @property {string} helpText - Optional additional information for the field label
 */

const formatter = (v, format) => {
	if (format?.type === 'CURRENCY') {
		return new Intl.NumberFormat('en-US', {
			currency: format.currencyType,
			currencyDisplay: 'narrowSymbol',
			style: 'currency',
			maximumFractionDigits: format.precision,
			minimumFractionDigits: format.precision,
		}).format(v);
	}
	return v;
};

/**
 * @description A draggable element for selecting a single point on a range.
 * @param {SliderProps} props
 * @param {Object} [ref]
 */
const Slider = ({
	id,
	htmlId,
	label = null,
	format,
	register,
	getValues,
	errors,
	min,
	max,
	increment,
	isEndorsable,
	isRequired,
	isDisabled,
	helpText,
	variables,
	viewId,
	control,
	...rest
}, ref) => {
	const sliderValue = useWatch({ control, name: id });

	const { eventCallback } = useEventContext();

	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<div id={`${htmlId}-container`} className="slider-container">
			<div id={`${htmlId}-input-and-value-wrapper`} className="slider-input-and-value-wrapper">
				<input
					id={htmlId}
					type="range"
					data-testid="slider"
					min={min}
					max={max}
					step={increment}
					className={`input-range${errors ? ' input-invalid' : ''}`}
					aria-invalid={!!errors || false}
					disabled={isDisabled}
					ref={ref}
					{...register(id, {
						onChange: () => eventCallback('onSlide', { timestamp: Date.now(), elementId: label, value: formatter(sliderValue || min) }),
					})}
					{...rest}
				/>
				<span id={`${htmlId}-slider-value`} className="slider-value">
					{formatter(sliderValue || min)}
				</span>
			</div>
			{errors ? (
				<p className="input-error" data-testid="error-message">
					{errors.message}
				</p>
			) : null}

		</div>
	);
};

export default forwardRef(Slider);
